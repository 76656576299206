import { NavLink } from '@solidjs/router';
import { StyledHorizontalSpace } from '../layout/layout.styles';
import {
    StyledDesktopHeader,
    StyledDesktopHeaderText,
    StyledHeaderLogo,
    StyledDesktopHeaderLeft,
    StyledDesktopHeaderRight,
} from './desktop-header.styles';
import LogoImage from '/client/assets/images/logo.png';

export const DesktopHeader = () => (
    <StyledDesktopHeader>
        <StyledDesktopHeaderLeft>
            <NavLink href="/">
                <StyledHeaderLogo>
                    <img src={LogoImage} alt="Gullviks logoyp" height="59" />
                </StyledHeaderLogo>
            </NavLink>
            <StyledHorizontalSpace size={0.5} />
            <StyledDesktopHeaderText>Gullvikspraktikan</StyledDesktopHeaderText>
        </StyledDesktopHeaderLeft>
        <StyledDesktopHeaderRight />
    </StyledDesktopHeader>
);
