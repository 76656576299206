import { mergeProps, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import type { ImageProps } from './image.d';
import { StyledImageContainer } from './image.styles';

export const Image = (props: ImageProps) => {
    const { imagesServiceUrl } = useContext(AppContext);

    const mergedProps = mergeProps(
        {
            alt: '',
            rounded: false,
            crop: 'original',
        },
        props
    );

    if (!props.src) {
        return null;
    }

    const imgSrc = () => `${imagesServiceUrl}/image/${mergedProps.crop}/${mergedProps.src}`;

    return <StyledImageContainer alt={mergedProps.alt} rounded={mergedProps.rounded} src={imgSrc()} />;
};
