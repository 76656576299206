import { Link } from '@solidjs/router';
import { For, Match, Show, Switch } from 'solid-js';
import { SetStoreFunction, Store } from 'solid-js/store';
import Button from '../../../button/button';
import { CropTypeCategory } from '../../../start/start-types';
import { StyledVerticalSpace } from '../../../layout/layout.styles';
import { FilterTypeStore } from '../../search';
import { StyledProductsList, StyledResultsContainer, StyledResultsInner } from './results.style';

type Props = {
    categories: CropTypeCategory[];
    filters: Store<FilterTypeStore>;
    setFilters: SetStoreFunction<FilterTypeStore>;
    exitSearch: (to?: string) => void;
};

export const Results = (props: Props) => {
    const firstModel = () => (props.filters.crop?.position === 1 ? props.filters.crop : props.filters.treatment);
    const secondModel = () => (props.filters.crop?.position === 2 ? props.filters.crop : props.filters.treatment);

    const getTextForProducts = () => {
        const { treatment, crop, strategies } = props.filters;

        if (treatment && crop) {
            return `${strategies.filtered.products.length} ${
                strategies.filtered.products.length === 1 ? 'produkt' : 'produkter'
            } kopplade till <strong>${firstModel()?.name}</strong> och <strong>${secondModel()?.name}</strong>`;
        }

        if (treatment) {
            return `${strategies.totals.products} produkter för <strong>${treatment?.name}</strong>. Ange en gröda i sökfältet ovan för ett bättre sökresultat.`;
        }

        return `${strategies.totals.products} produkter för <strong>${crop?.name}</strong>. Ange ett mål i sökfältet ovan för ett bättre sökresultat.`;
    };

    const paginatedProducts = () => {
        const { pagination, products } = props.filters.strategies.filtered;
        return [...products].slice(0, pagination.limit * pagination.page);
    };

    const paginate = () => {
        const { pagination } = props.filters.strategies.filtered;
        props.setFilters('strategies', 'filtered', 'pagination', 'page', pagination.page + 1);
    };

    const paginateDisabled = () => {
        const { pagination, products } = props.filters.strategies.filtered;
        return products.length <= pagination.limit * pagination.page;
    };

    const showPaginateButton = () => {
        const { pagination, products } = props.filters.strategies.filtered;
        return products.length > pagination.limit;
    };

    const getTextForTreatments = () =>
        `${props.filters.strategies.totals.treatments} mål för <strong>${props.filters.crop?.name}</strong>. Ange ett mål i sökfältet ovan för ett bättre sökresultat.`;

    const getTextForCrops = () => {
        const { crops } = props.filters.strategies.totals;
        return `${crops} ${crops === 1 ? 'gröda' : 'grödor'} för <strong>${
            props.filters.treatment?.name
        }</strong>. Ange en gröda i sökfältet ovan för ett bättre sökresultat.`;
    };

    const getTextForStrategies = () => {
        const { strategies } = props.filters.strategies.filtered;

        return `${strategies.length} ${strategies.length === 1 ? 'strategi' : 'strategier'} för <strong>${
            firstModel()?.name
        }</strong> och <strong>${secondModel()?.name}</strong>`;
    };

    const getLinkToStrategies = () => {
        if (!props.filters.crop) {
            return '';
        }

        const category = props.categories?.find(
            ({ cropTypes }) => !!cropTypes?.find((cropType) => cropType.slug === props.filters.crop?.cropType?.slug)
        );

        const link = `/strategier/${category?.slug}/${props.filters.crop?.cropType?.slug}/${props.filters.crop?.slug}`;
        if (!props.filters.treatment) {
            return link;
        }

        return `${link}/${props.filters.treatment?.purpose?.slug}/resultat?mal=${props.filters.treatment?.slug}`;
    };

    const navigateToStrategies = () => {
        props.exitSearch(getLinkToStrategies());
    };

    return (
        <StyledResultsContainer>
            <StyledResultsInner>
                <Show when={props.filters.strategies.filtered.products.length}>
                    <p innerHTML={getTextForProducts()} />
                    <Show
                        when={props.filters.strategies.filtered.pagination.visible}
                        fallback={
                            <Button
                                label="Visa alla relaterade produkter"
                                onClick={() => props.setFilters('strategies', 'filtered', 'pagination', 'visible', true)}
                            />
                        }
                    >
                        <StyledProductsList>
                            <For each={paginatedProducts()}>
                                {(product) => (
                                    <li>
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.exitSearch(`/kategorier/produkter/${product.slug}`);
                                            }}
                                            href="#"
                                        >
                                            {product.name}
                                        </Link>
                                    </li>
                                )}
                            </For>
                            <Show when={showPaginateButton()}>
                                <StyledVerticalSpace size={1} />
                                <Button label="Visa fler" onClick={() => paginate()} disabled={paginateDisabled()} />
                            </Show>
                        </StyledProductsList>
                    </Show>
                </Show>
            </StyledResultsInner>
            <StyledResultsInner>
                <Switch>
                    <Match when={props.filters.crop && !props.filters.treatment}>
                        <p innerHTML={getTextForTreatments()} />
                        <Button onClick={navigateToStrategies} label="Visa alla relaterade mål" />
                    </Match>
                    <Match when={props.filters.treatment && !props.filters.crop}>
                        <p innerHTML={getTextForCrops()} />
                    </Match>
                    <Match when={props.filters.treatment && props.filters.crop}>
                        <p innerHTML={getTextForStrategies()} />
                        <Button onClick={navigateToStrategies} label="Visa strategier" />
                    </Match>
                </Switch>
            </StyledResultsInner>
        </StyledResultsContainer>
    );
};
