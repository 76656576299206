import { styled } from 'solid-styled-components';
import { colors } from '../../../style/style-variables';

export const StyledDarkenLayer = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const StyledDialogWrapper = styled.div`
    position: fixed;
    background: ${colors.white};
    top: 0;
    right: 0;
    bottom: 0;
    transition: all 0.25s ease-out;
    z-index: 10;
    height: 100%;
    overflow: scroll;

    &.modal-enter,
    &.modal-leave-active {
        transform: scale(0.25);
        opacity: 0;
    }

    &.modal-exit-active {
        transition: all 0.15s ease-out;
        transform: scale(0.5);
        opacity: 0;
    }

    @media (max-width: 768px) {
        left: 0;
    }

    @media (min-width: 769px) {
        box-shadow: 0px 2px 15px rgb(0 0 0 / 20%);
        width: 75%;
        min-width: 750px;
        max-width: 1200px;

        &.modal-enter,
        &.modal-leave-active {
            transform: translateX(100%);
            opacity: 0;
        }

        &.modal-exit-active {
            transition: all 0.15s ease-out;
            transform: translateX(200%);
            opacity: 0;
        }
    }
`;

export const StyledDialogHeader = styled.div`
    padding: 1.2rem;
    text-align: right;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;


    button {
        background: none;
        padding: 0;

        svg {
            height: 30px;
            width: 30px;
        }
    }
`;

export const StyledDialogContent = styled.div`
    padding: 0 1.5rem;
`;
