import { styled } from 'solid-styled-components';

export const StyledGrowthStages = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    gap: 1.8rem;
    align-items: flex-end;

    @media (max-width: 389px) {
        display: none;
    }
`;

export const StyledGrowthStageImage = styled.img<StyledGrowthStageImageProps>`
    @media (max-width: 768px) {
        ${(p) => (p.group !== 4 ? 'display: none;' : '')}
    }

    @media (min-width: 1035px) and (max-width: 1340px) {
        ${(p) => (p.group === 2 ? 'display: none;' : '')}
    }

    @media (min-width: 769px) and (max-width: 1035px) {
        ${(p) => (p.group === 2 || p.group === 3 ? 'display: none;' : '')}
    }
`;

export type StyledGrowthStageImageProps = {
    group: number;
};
