import {styled} from 'solid-styled-components';

export const StyledRegCheckSearchNoResults = styled.div`
    margin-top: 2rem;
`;

export const StyledRegCheckSearchResults = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    overflow: auto;
    background-color: #fff;

    .regkoll-list-enter-active,
    .regkoll-list-exit-active {
        transition: opacity 0.2s, transform 0.2s;
    }
    .regkoll-list-exit-active {
        opacity: 0;
        display: none;
        height: 0;
    }
    .regkoll-list-enter,
    .regkoll-list-exit-to {
        transform: translateY(100%);
        opacity: 0;
    }
    .regkoll-list-enter {
        transform: translateY(-100%);
    }
`;
