import type { ImageLinkCardProps } from "./image-link-card-types"
import {
    StyledImageLinkCard,
    StyledCardText,
    StyledCardBottom,
    StyledCardImage,
} from "./image-link-card.styles";

export const ImageLinkCard = (props: ImageLinkCardProps) => (
    <StyledImageLinkCard href={props.href} onClick={props.onClick}>
        <StyledCardImage imageUrl={props.imageUrl}></StyledCardImage>
        <StyledCardBottom backgroundColor={props.bottomBackgroundColor}>
            <StyledCardText>{props.name}</StyledCardText>
        </StyledCardBottom>
    </StyledImageLinkCard>
);
