import { For, mergeProps } from 'solid-js';
import { StyledSkeletonLoader } from './skeleton-loader.styles';

export const SkeletonLoader = (props: { height?: number; numBlocks?: number; rounded?: boolean; marginBottom?: string }) => {
    const computedProps = mergeProps({ height: 52, numBlocks: 3, rounded: true, marginBottom: '0.5rem' }, props);
    const loaders = Array.from({ length: computedProps.numBlocks });

    return (
        <For each={loaders}>
            {() => (
                <StyledSkeletonLoader
                    height={computedProps.height}
                    rounded={computedProps.rounded}
                    marginBottom={computedProps.marginBottom}
                />
            )}
        </For>
    );
};
