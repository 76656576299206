import { createSignal, Match, Show, Switch } from 'solid-js';
import { StyledVerticalSpace } from '../layout/layout.styles';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';
import { Tabs } from '../tabs/tabs';
import { TabsOption } from '../tabs/tabs-types';
import { ProductEffectSchema } from './product-effect-schema';
import { ProductInfo } from './product-info';
import type { ProductProps } from './product-types';
import { StyledProductContainer, StyledProductSkeletons } from './products.styles';
import { Upmas } from './upma/upmas';

export const Product = (props: ProductProps) => {
    const [view, setView] = createSignal('info');
    const product = () => props.productData()?.product;
    const hasUpmas = () => product()?.registrationNumbers?.some((regNo) => regNo?.upmas?.length);

    const tabOptions = (): TabsOption[] => [
        {
            value: 'info',
            text: 'Info',
        },
        {
            value: 'effects',
            text: 'Effektschema',
            disabled: !product()?.effects?.length,
        },
        {
            value: 'upma',
            text: 'UPMA',
            disabled: !hasUpmas(),
        },
    ];

    return (
        <StyledProductContainer>
            <Show when={props.showHeader}>
                <h1>{props.productData.loading ? '' : product()?.name}</h1>
            </Show>
            <Show when={!props.productData()?.product.children?.length}>
                <Tabs options={tabOptions()} value={view()} onChange={(val: string) => setView(val)} />
            </Show>
            <StyledVerticalSpace size={1} />
            <Show
                keyed
                when={props.productData()?.product}
                fallback={
                    <StyledProductSkeletons>
                        <SkeletonLoader numBlocks={1} />
                        <SkeletonLoader numBlocks={2} height={105} />
                    </StyledProductSkeletons>
                }
            >
                {(product) => (
                    <Switch>
                        <Match when={view() === 'info'}>
                            <ProductInfo product={product} />
                        </Match>
                        <Match when={view() === 'effects'}>
                            <Show when={product?.effects.length} fallback={<p>Inga effekter hittades...</p>}>
                                <ProductEffectSchema product={product} />
                            </Show>
                        </Match>
                        <Match when={view() === 'upma'}>
                            <Upmas registrationNumbers={product?.registrationNumbers || []} />
                        </Match>
                    </Switch>
                )}
            </Show>
        </StyledProductContainer>
    );
};
