import { createGlobalStyles } from 'solid-styled-components';
import { colors } from '../style/style-variables';

export const GlobalStyles = () => {
    const Styles = createGlobalStyles`
        html,
        body {
            padding: 0;
            margin: 0;
            background: #F2F2F2;
            color: #262626;
            font-size: 16px;
            font-family: -apple-system, BlinkMacSystemFont, "SF Pro", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            line-height: 1.4;
            box-sizing: border-box;

            @media (min-width: 960px) {
                font-size: 15px;
            }
        }

        h1, h2, h3, h4, h5, h6, p, ul, ol, dl {
            margin: 0 0 1rem;
            padding: 0;
        }

        h1, h2, h3, h4, h5, h6 {
            font-weight: bold;
        }

        h1 {
            font-size: 24px;
        }

        h2 {
            font-size: 21px;
        }

        h3 {
            font-size: 18px;
        }

        h4 {
            font-size: 16px;
        }

        h5, h6 {
            font-size: 14px;
        }

        ul, ol, dl {
            padding-left: 1rem;

            li {
                padding-bottom: 0.25rem;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        a, a:visited {
            color: ${colors.red};
            text-decoration: none;
        }
    `;
    return <Styles />;
};
