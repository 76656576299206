import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Contacts } from '../../components/contacts/contacts';
import { getContactAreasQuery } from '../../graphql/queries/contact-areas';
import { getContactsQuery } from '../../graphql/queries/contacts';

export const ContactsContainer = () => {
    const { createCachedResource } = useContext(AppContext);

    const [contactsData] = createCachedResource(getContactsQuery);
    const [contactAreasData] = createCachedResource(getContactAreasQuery);

    const contacts = () => contactsData()?.contacts?.rows || [];
    const contactAreas = () => contactAreasData()?.contactAreas?.rows || [];

    return (
        <Show when={contacts().length && contactAreas().length}>
            <Contacts contacts={contacts()} contactAreas={contactAreas()} />
        </Show>
    );
};
