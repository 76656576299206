import { useParams } from '@solidjs/router';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Screen } from '../../components/layout/screen/screen';
import { Treatments } from '../../components/treatments/treatments';
import { getCropQuery, getCropTypeCategoryQuery, getCropTypeQuery } from '../../graphql/queries/crops';
import { getPurposeQuery } from '../../graphql/queries/purpose';
import { getStrategiesQuery } from '../../graphql/queries/strategies';

export const TreatmentsContainer = () => {
    const { createCachedResource } = useContext(AppContext);
    const { categorySlug, cropTypeSlug, cropSlug, purposeSlug } = useParams();

    const [getCropTypeCategoryData] = createCachedResource(getCropTypeCategoryQuery, { slug: categorySlug });
    const [cropTypeData] = createCachedResource(getCropTypeQuery, { slug: cropTypeSlug });
    const [cropData] = createCachedResource(getCropQuery, { slug: cropSlug });
    const [purposeData] = createCachedResource(getPurposeQuery, { slug: purposeSlug });
    const [strategiesData] = createCachedResource(getStrategiesQuery, { cropSlug, treatmentSlugs: [] });

    return (
        <Screen>
            <Treatments
                cropData={cropData}
                purposeData={purposeData}
                cropTypeData={cropTypeData}
                strategiesData={strategiesData}
                cropTypeCategoryData={getCropTypeCategoryData}
            />
        </Screen>
    );
};
