import { styled } from 'solid-styled-components';

// This layout is werid because <Breadcrumbs/> is a child of <Screen/>,
// (that's where the data is provided) but thanks to a quirky desktop layout
// we need to position this absolutely like so:
export const StyledBreadCrumbs = styled.div`
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: normal;

    @media (max-width: 769px) {
        display: none;
    }
`;

export const StyledBreadCrumbDivider = styled.div`
    color: black;
    margin: 0 1.25rem;
`;

export const StyledPrompt = styled.div`
    color: black;
`;
