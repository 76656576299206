import GrowthStage1 from '../../../../client/assets/images/growth-stages/growth-1.png';
import GrowthStage2 from '../../../../client/assets/images/growth-stages/growth-2.png';
import GrowthStage3 from '../../../../client/assets/images/growth-stages/growth-3.png';
import GrowthStage4 from '../../../../client/assets/images/growth-stages/growth-4.png';
import GrowthStage5 from '../../../../client/assets/images/growth-stages/growth-5.png';
import GrowthStage6 from '../../../../client/assets/images/growth-stages/growth-6.png';
import GrowthStage7 from '../../../../client/assets/images/growth-stages/growth-7.png';
import GrowthStage8 from '../../../../client/assets/images/growth-stages/growth-8.png';
import GrowthStage9 from '../../../../client/assets/images/growth-stages/growth-9.png';
import GrowthStage10 from '../../../../client/assets/images/growth-stages/growth-10.png';
import GrowthStage11 from '../../../../client/assets/images/growth-stages/growth-11.png';
import GrowthStage12 from '../../../../client/assets/images/growth-stages/growth-12.png';
import GrowthStage13 from '../../../../client/assets/images/growth-stages/growth-13.png';
import GrowthStage14 from '../../../../client/assets/images/growth-stages/growth-14.png';

import { StyledGrowthStages, StyledGrowthStageImage } from './growth-stages.styles';

export const GrowthStages = () => (
    <StyledGrowthStages>
        <StyledGrowthStageImage src={GrowthStage1} width={34} height={39} alt="En gröda som växer" group={3}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage2} width={47} height={55} alt="En gröda som växer" group={3}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage3} width={74} height={55} alt="En gröda som växer" group={1}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage4} width={75} height={81} alt="En gröda som växer" group={4}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage5} width={74} height={100} alt="En gröda som växer" group={4}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage6} width={75} height={116} alt="En gröda som växer" group={4}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage7} width={76} height={151} alt="En gröda som växer" group={4}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage8} width={75} height={175} alt="En gröda som växer" group={2}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage9} width={75} height={186} alt="En gröda som växer" group={1}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage10} width={74} height={221} alt="En gröda som växer" group={1}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage11} width={75} height={241} alt="En gröda som växer" group={2}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage12} width={75} height={241} alt="En gröda som växer" group={3}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage13} width={76} height={251} alt="En gröda som växer" group={2}></StyledGrowthStageImage>
        <StyledGrowthStageImage src={GrowthStage14} width={74} height={251} alt="En gröda som växer" group={1}></StyledGrowthStageImage>
    </StyledGrowthStages>
);
