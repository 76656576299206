import { For, Show } from 'solid-js';
import {
    StyledAdditionalConditions,
    StyledAdditionalConditionsItem,
    StyledAdditionalConditionsItems,
    StyledAdditionalConditionsItemRow,
    StyledAdditionalConditionsItemHeader,
} from './additional-conditions.styles';
import { ChemicalInspectionTableData } from '../product-types';
import { unwrap } from 'solid-js/store';

type AdditionalConditionsProps = {
    tableData: ChemicalInspectionTableData;
};

export const AdditionalConditions = (props: AdditionalConditionsProps) => {
    const tableData = () => {
        const tableData = { ...unwrap(props.tableData) };
        // Remove the final row (Ändring) which we don't need to show
        return [...tableData.rows].map((row) => row.slice(0, row.length - 1));
    };

    return (
        <StyledAdditionalConditions>
            <p>
                <strong>{props.tableData.title}</strong>
            </p>
            <StyledAdditionalConditionsItems>
                <For each={tableData()}>
                    {(row) => (
                        <StyledAdditionalConditionsItem>
                            <For each={row}>
                                {(cell, index) => (
                                    <Show
                                        when={index() !== 0}
                                        fallback={<StyledAdditionalConditionsItemHeader>{cell}</StyledAdditionalConditionsItemHeader>}
                                    >
                                        <StyledAdditionalConditionsItemRow>
                                            <p>{`${props.tableData.header[index()].trim()}:`}</p>
                                            <p>{cell}</p>
                                        </StyledAdditionalConditionsItemRow>
                                    </Show>
                                )}
                            </For>
                        </StyledAdditionalConditionsItem>
                    )}
                </For>
            </StyledAdditionalConditionsItems>
        </StyledAdditionalConditions>
    );
};
