import { styled } from 'solid-styled-components';

export const StyledInputComponent = styled.div<{
    backgroundColor?: string;
}>`
    font-size: 16px;

    input[type='date'] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-width: calc(100% - 20px);
        height: 1.25rem;
        background: white;
        font-family: inherit;
    }
`;

export const StyledInput = styled.input<StyledInputProps>`
    border: none;
    padding: 10px;
    border: 1px solid #a4343a;
    border-radius: 8px;
    width: calc(100% - 20px);
    font-family: inherit;
    font-size: 1rem;
`;

export type StyledInputProps = {
    backgroundColor?: string;
};

export const StyledTextarea = styled.textarea`
    border: none;
    padding: 10px;
    display: block;
    border: 1px solid #a4343a;
    border-radius: 8px;
    width: calc(100% - 20px);
    min-height: 80px;
`;

export const StyledInputWrapper = styled.div`
    width: 100%;
`;

export const InputLabel = styled.label`
    display: block;
    font-weight: bold;
    padding-bottom: 0.5rem;
`;
