import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';

export const StyledSelectIndexWrapper = styled.div`
    width: 100%;
`;

export const StyledSelectWrapper = styled.div`
    user-select: none;
    width: 100%;
`;

export const StyledSelectComponent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.red};
    border-radius: 8px;
`;

export const StyledSelectField = styled.div<StyledSelectFieldProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #222;
    background: ${(p) => (p.disabled ? '#ccc' : '#ffffff')};
    cursor: ${(p) => (p.searchable ? 'text' : 'pointer')};
    border-radius: 8px;
    padding: 4px 12px;
    min-height: 33px;
`;

export type StyledSelectFieldProps = {
    disabled?: boolean;
    searchable?: boolean;
};

export const StyledSelectOptions = styled.div`
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    z-index: 10;
    margin-top: 2px;
    max-height: 400px;
    overflow: auto;
`;

export const StyledSelectOption = styled.div<{ disabled?: boolean }>`
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #3b3b3b;
    background-color: white;
    transition: background-color 90ms ease-in;

    &:hover {
        cursor: ${(p) => (p.disabled ? 'normal' : 'pointer')};
        background-color: ${(p) => (p.disabled ? 'white' : '#f2f2f2')};
    }
`;

export const StyledMultiSelectOption = styled(StyledSelectOption)<StyledMultiSelectOptionProps>`
    background-color: ${(p) => (p.selected ? '#f0fcff' : 'white')};
`;

type StyledMultiSelectOptionProps = {
    selected: boolean;
};

export const StyledLabel = styled.label`
    font-weight: bold;
    display: block;
    color: #222;
    padding-bottom: 0.5rem;
`;

export const StyledArrow = styled.div<StyledArrowProps>`
    position: relative;
    height: 16px;
    width: 16px;
    flex-shrink: 0;
    margin-right: 8px;
`;

type StyledArrowProps = {
    open: boolean;
};

export const StyledSelectedOptions = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
`;

export const StyledSelectedOption = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f1f5d2;
    color: #222;
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 400;
`;

export const StyledSelectedOptionDeleteIcon = styled.div`
    color: #607f0b;
    opacity: 0.5;
    margin-left: 0.5rem;
`;

export const StyledSelectValue = styled.div``;

export const StyledSelectValuePlaceholder = styled.div`
    height: 16px;
`;

export const StyledEmptySelectOptionHolder = styled.div`
    padding: 0.5rem;
`;

export const StyledArrowIcon = styled.div`
    position: absolute;
    right: 0.5rem;
`;
