import { styled } from 'solid-styled-components';

export const StyledDropdownListContainer = styled.div<{ isLoading: boolean }>`
    position: relative;
    opacity: ${(p) => (p.isLoading ? 0.9 : 1)};
`;

export const StyledDropdownListInner = styled.div`
    position: absolute;
    background-color: white;
    max-height: 440px;
    overflow: auto;
    left: 0;
    right: 0;
    border-radius: 0 0 8px 8px;

    &:not(:empty) {
        margin-top: 0.5rem;
        padding: 0.5rem;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    }
`;

export const StyledDropdownListHeader = styled.h3`
    margin: 0;
    padding: 0.5rem;
    font-size: 16px;
    font-weight: bold;
`;

export const StyledDropdownList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
    }
`;

export const UnstyledButton = styled.button`
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    font-size: 16px;
    background-color: white;
    transition: background 100ms ease-in-out;
    border-radius: 6px;
    color: #262626;

    &:hover {
        background-color: #fafafa;
    }
`;
