import { useParams } from '@solidjs/router';
import { useContext, Show } from 'solid-js';
import { CropTypes } from '../../components/crop-types/crop-types';
import { Screen } from '../../components/layout/screen/screen';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getCropTypeCategoryQuery } from '../../graphql/queries/crops';

export const CropTypesContainer = () => {
    const params = useParams();
    const { createCachedResource } = useContext(AppContext);
    const [cropTypeCategoryData] = createCachedResource(getCropTypeCategoryQuery, () => ({ slug: params.categorySlug }), true);

    return (
        <Screen>
            <Show when={!cropTypeCategoryData.error}>
                <CropTypes cropTypeCategoryData={cropTypeCategoryData} />
            </Show>
        </Screen>
    );
};
