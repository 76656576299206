import { createSignal, For, onMount, Show, useContext } from 'solid-js';
import { LocalStorageDaytimeDegrees } from '../../containers/daytime-degrees/daytime-degrees-types';
import { LinkButton } from '../link-button/link-button';
import { DaytimeDegreesItemContainer } from '../../containers/daytime-degrees/daytime-degrees-item-container';
import { StyledButtonWrapper, StyledDaytimeDegreesListEmptyMessage } from './daytime-degrees-list.styles';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getCropTypeCategoriesQuery } from '../../graphql/queries/crops';
import { CropTypeCategory } from '../start/start-types';
import { getTreatmentsQuery } from '../../graphql/queries/treatments';
import { Treatment } from '../treatments/treatments.d';
import { Container } from '../layout/container/container';
import { getFromLocalStorage, setLocalStorage } from '../../containers/daytime-degrees/dd-localstorage';
import { getDaytimeDegreesCropsQuery } from '../../graphql/queries/daytime-degrees';

type DaytimeDegreesList = {
    daytimeDegrees: LocalStorageDaytimeDegrees[];
};

export const DaytimeDegreesList = () => {
    const [error, setError] = createSignal<null | string>(null);
    const [emptyMessage, setEmptyMessage] = createSignal('Laddar...');
    const { createCachedResource } = useContext(AppContext);
    const [localStorageDaytimeDegrees, setLocalStorageDaytimeDegrees] = createSignal<LocalStorageDaytimeDegrees[]>([]);

    const [daytimeDegreesCrops] = createCachedResource(getDaytimeDegreesCropsQuery, { limit: 1000 });
    const [cropTypeCategoriesData] = createCachedResource(getCropTypeCategoriesQuery);
    const [treatmentsData] = createCachedResource(getTreatmentsQuery);
    const categories = (): CropTypeCategory[] => cropTypeCategoriesData()?.cropTypeCategories?.rows || [];
    const treatments = (): Treatment[] => treatmentsData()?.treatments?.rows || [];

    onMount(() => {
        setEmptyMessage('Du har inte lagt till några gradkontroller ännu');

        try {
            setLocalStorageDaytimeDegrees(getFromLocalStorage());
        } catch (err) {
            setError('Det uppstod ett fel när daggrader skulle hämtas.');
        }
    });

    const removeItem = (index: number) => {
        if (!window.confirm('Är du säker på att du vill ta bort denna gradkoll?') || !localStorageDaytimeDegrees()) {
            return;
        }

        const newItems: LocalStorageDaytimeDegrees[] = [...localStorageDaytimeDegrees()];
        newItems.splice(index, 1);

        setLocalStorage(newItems);
        setLocalStorageDaytimeDegrees(newItems);
    };

    return (
        <div style={{ 'padding-bottom': '2rem' }}>
            <Show
                when={localStorageDaytimeDegrees().length}
                fallback={<StyledDaytimeDegreesListEmptyMessage>{emptyMessage()}</StyledDaytimeDegreesListEmptyMessage>}
            >
                <Show when={error()}>
                    <Container>{error()}</Container>
                </Show>
                <For each={localStorageDaytimeDegrees()}>
                    {(daytimeDegrees: LocalStorageDaytimeDegrees, index) => (
                        <DaytimeDegreesItemContainer
                            index={index()}
                            removeItem={() => removeItem(index())}
                            categories={categories()}
                            treatments={treatments()}
                            daytimeDegreesCrops={daytimeDegreesCrops()}
                            {...daytimeDegrees}
                        />
                    )}
                </For>
            </Show>
            <StyledButtonWrapper>
                <LinkButton href="/daggrader/ny" label="Lägg till ny gradkoll" />
            </StyledButtonWrapper>
        </div>
    );
};
