import {  For } from 'solid-js';
import { isNumeric } from '../../tools/is-numeric';
import { Box } from '../layout/box/box';
import { StyledVerticalSpace } from '../layout/layout.styles';
import { Row } from '../layout/row/row';
import { Effect, EffectSchemaProps } from './product-types';
import { StyledColumn, StyledEffectColumn, StyledEffectSchema, StyledEffectDoseInfo } from './products.styles';

export const ProductEffectSchema = (props: EffectSchemaProps) => {
    const getSortedTreatments = (effect: Effect) => {
        const numeric = effect.treatments.filter(({ dose }) => isNumeric(dose));
        const sortedNumeric = numeric.sort((a, b) => parseInt(b.dose) - parseInt(a.dose));

        const text = effect.treatments.filter(({ dose }) => !isNumeric(dose));

        return [...sortedNumeric, ...text].filter(({ dose }) => !!dose);
    };

    return (
        <StyledEffectSchema>
            <For each={props.product.effects}>
                {(effect) => (
                    <Box>
                        <Row justifyContent="space-between">
                            <div>
                                <strong> {effect.crop.name}</strong>
                                <StyledEffectDoseInfo>Effekt gäller för dos: {effect.dosageInformation}</StyledEffectDoseInfo>
                            </div>
                            <StyledColumn> % </StyledColumn>
                        </Row>
                        <StyledVerticalSpace size={0.75} />
                        <For each={getSortedTreatments(effect)}>
                            {(treatment) => (
                                <Row justifyContent="space-between">
                                    <span>{treatment.name}</span>
                                    <StyledEffectColumn effect={treatment.dose}>{treatment.dose}</StyledEffectColumn>
                                </Row>
                            )}
                        </For>
                    </Box>
                )}
            </For>
        </StyledEffectSchema>
    );
};
