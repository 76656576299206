import { useParams } from '@solidjs/router';
import { useContext } from 'solid-js';
import { Purposes } from '../../components/purposes/purposes';
import { getCropQuery, getCropTypeCategoryQuery, getCropTypeQuery } from '../../graphql/queries/crops';
import { getPurposesQuery } from '../../graphql/queries/purpose';
import { Screen } from '../../components/layout/screen/screen';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getStrategiesQuery } from '../../graphql/queries/strategies';

export const PurposesContainer = () => {
    const { createCachedResource } = useContext(AppContext);
    const { categorySlug, cropTypeSlug, cropSlug } = useParams();

    const [cropTypeCategoryData] = createCachedResource(getCropTypeCategoryQuery, { slug: categorySlug });
    const [strategiesData] = createCachedResource(getStrategiesQuery, { cropSlug, treatmentSlugs: [] });
    const [cropTypeData] = createCachedResource(getCropTypeQuery, { slug: cropTypeSlug });
    const [cropData] = createCachedResource(getCropQuery, { slug: cropSlug });
    const [purposesData] = createCachedResource(getPurposesQuery, { limit: 100 });

    return (
        <Screen>
            <Purposes
                cropData={cropData}
                cropTypeData={cropTypeData}
                purposesData={purposesData}
                strategiesData={strategiesData}
                cropTypeCategoryData={cropTypeCategoryData}
            />
        </Screen>
    );
};
