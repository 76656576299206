import { styled } from 'solid-styled-components';

const statusColors = {
    expired: 'rgba(255, 46, 0, 0.60)',
    outgoing: '#fcbc05',
    approved: 'rgba(75, 174, 79, 0.60)',
};

export const StyledRegCheckSearchResultsItem = styled.div`
    cursor: pointer;
    border-radius: 0.5rem;
    background: rgba(250, 187, 0, 0.2);

    mark {
        background: rgb(240, 240, 10);
    }
`;

export const StyledRegCheckSearchResultsItemTop = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
`;

export const StyledRegistrationNumberCheckItemStatusDotContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledRegistrationNumberCheckItemStatusDot = styled.div<{ status: string }>`
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: ${(props) => statusColors[props.status as keyof typeof statusColors]};
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledRegCheckSearchResultsItemLeft = styled.div`
    display: flex;
    gap: 1rem;
`;

export const StyledRegCheckSearchResultsItemName = styled.p`
    overflow-wrap: anywhere;
    font-weight: 700;
    margin: 0;
    font-size: 1.25rem;
`;

export const StyledRegCheckSearchResultsItemRight = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    svg {
        transition: transform 200ms;
        transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
`;

export const StyledRegCheckSearchResultsItemBottom = styled.div`
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;
    overflow: hidden;
    grid-row: 1 / span 2;
`;

export const StyledRegCheckSearchResultsItemBottomRow = styled.div<{ center?: boolean }>`
    display: flex;
    justify-content: ${(p) => (p.center ? 'center' : 'space-between')};
    align-items: center;
    border-bottom: 1px solid rgba(255, 168, 1, 0.3);
    padding: 1rem 0;
    margin: 0 1rem;

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
`;

export const StyledRegCheckSearchResultsItemBottomAccordion = styled.div<{ open: boolean }>`
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 250ms;

    ${(props) => (props.open ? 'grid-template-rows: 1fr; padding: .5rem 0 1rem' : '')};
`;
