import { createUniqueId } from 'solid-js';
import { CheckboxProps } from './checkbox.d';
import {
    StyledCheckboxComponentPlain,
    StyledCheckboxInput,
} from './checkbox.styles';

const Checkbox = (props: CheckboxProps) => {
    const id = props.id || createUniqueId();

    return (
        <StyledCheckboxComponentPlain>
            <StyledCheckboxInput
                id={id}
                type="checkbox"
                checked={props.checked}
                disabled={props.disabled}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={() => {
                    props.onChange(!props.checked);
                }}
            />
            <label for={id}>{props.label}</label>
        </StyledCheckboxComponentPlain>
    );
};

export default Checkbox;
