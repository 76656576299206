import { LocalStorageDaytimeDegrees } from './daytime-degrees-types.d';
import { isLocalStorageAvailable } from '../../../lib/localstorage';

const KEY = 'daytimeDegrees';

export const getFromLocalStorage = () => {
    if (!isLocalStorageAvailable()) {
        return [];
    }

    const localStorageDaytimeDegreesString = localStorage.getItem(KEY);
    return localStorageDaytimeDegreesString ? (JSON.parse(localStorageDaytimeDegreesString) as LocalStorageDaytimeDegrees[]) : [];
};

export const setLocalStorage = (items: LocalStorageDaytimeDegrees[]) => {
    if (!isLocalStorageAvailable()) {
        return;
    }

    localStorage.setItem(KEY, JSON.stringify(items));
};

export const updateLocalStorageItem = (index: number, item: Partial<LocalStorageDaytimeDegrees>) => {
    const localStorage = [...getFromLocalStorage()];
    localStorage[index] = { ...localStorage[index], ...item };

    setLocalStorage(localStorage);
};
