import { Accessor, For, Setter } from 'solid-js';
import { StyledVerticalSpace } from '../layout/layout.styles';
import type { StrategiesResponse, Strategy } from './strategies.d';
import { StyledSpringAutumChoicesContainer, StyledSpringAutumHeader, StyledSpringAutumnChoice } from './strategies.styles';

type Props = {
    strategies: () => StrategiesResponse | undefined;
    value: Accessor<Strategy['springAutumnTreatment']>;
    onChange: Setter;
};

export const AutumnSpringFilter = (props: Props) => {
    const filterOptions = () => [
        {
            text: 'Visa alla',
            value: '',
        },
        {
            text: 'Höst',
            value: 'AUTUMN',
        },
        {
            text: 'Vår',
            value: 'SPRING',
        },
    ];

    const isDisabled = (value: Strategy['springAutumnTreatment']) => {
        if (value === '') {
            return false;
        }

        const options = props.strategies()?.rows?.map(({ springAutumnTreatment }) => springAutumnTreatment) || [];
        return !new Set(options).has(value);
    };

    return (
        <div>
            <StyledSpringAutumHeader>Välj odlingssäsong:</StyledSpringAutumHeader>
            <StyledSpringAutumChoicesContainer>
                <For each={filterOptions()}>
                    {(option) => (
                        <StyledSpringAutumnChoice
                            disabled={isDisabled(option.value as Strategy['springAutumnTreatment'])}
                            isActive={props.value() === option.value}
                            onClick={() => props.onChange(option.value)}
                            role="button"
                        >
                            {option.text}
                        </StyledSpringAutumnChoice>
                    )}
                </For>
            </StyledSpringAutumChoicesContainer>
        </div>
    );
};
