import { createResource, createSignal, For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import {
    DaytimeDegreesEventRelatedModel,
    DaytimeDegreesEventStrategy,
    DaytimeDegreesItemEventType,
} from '../../containers/daytime-degrees/daytime-degrees-types';
import { getStrategiesQuery } from '../../graphql/queries/strategies';
import Button from '../button/button';
import { Crop } from '../crops/crops.d';
import { ChevronDownIcon } from '../icons/chevron';
import { StyledVerticalSpace } from '../layout/layout.styles';
import { Row } from '../layout/row/row';
import { Link } from '../link/link';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';
import { CropTypeCategory } from '../start/start-types';
import { Treatment } from '../treatments/treatments.d';
import {
    StyledDaytimeDegreesEvent,
    StyledProgressBar,
    StyledProgressBarFiller,
    StyledProgressInfo,
    StyledProgressNumber,
    StyledProgressNumberRow,
    StyledTempSpanHighNumber,
    StyledChevronWrapper,
    StyledEventMainContent,
    StyledEventExpandableContent,
    StyledTextHeading,
} from './daytime-degrees-item-event.styles';
import { strategyTitle } from './strategy-name';

type DaytimeDegreesEventProps = DaytimeDegreesItemEventType & {
    crop: Crop;
    hideEvent: () => void;
    allTreatments: Treatment[];
    categories: CropTypeCategory[];
    products?: DaytimeDegreesEventRelatedModel[];
};

export const DaytimeDegreesItemEvent = (props: DaytimeDegreesEventProps) => {
    const { graphQlClient } = useContext(AppContext);
    const [open, setOpen] = createSignal(false);

    const getProgressPercentage = () => {
        const percentage = Math.round((props.accumulatedDaytimeDegreesTemp / (props.tempSpanHigh || props.tempSpanLow)) * 100);
        return percentage > 100 ? 100 : percentage;
    };

    const [strategies] = createResource(open, async (open) => {
        // Only load strategies when asked for and when the dropdown is open
        if (!props.showStrategies || !open) {
            return {
                count: 0,
                rows: [],
            };
        }

        const { strategies } = await graphQlClient(getStrategiesQuery, {
            cropSlug: props.crop.slug,
            treatmentSlugs: props.treatments.map(({ slug }) => slug),
        });

        return strategies;
    });

    const getLinkToStrategies = () => {
        if (!props.crop) {
            return '';
        }

        const category = props.categories?.find(
            ({ cropTypes }) => !!cropTypes?.find((cropType) => cropType.slug === props.crop?.cropType?.slug)
        );

        const link = `/strategier/${category?.slug}/${props.crop?.cropType?.slug}/${props.crop?.slug}`;
        const treatment = props.allTreatments.find(({ slug }) => props.treatments[0]?.slug === slug);

        if (!treatment) {
            return link;
        }

        return `${link}/${treatment.purpose?.slug}/resultat?mal=${props.treatments?.map(({ slug }) => slug).join(',')}`;
    };

    return (
        <StyledDaytimeDegreesEvent>
            <StyledEventMainContent percentage={getProgressPercentage()} onClick={() => setOpen(!open())}>
                <StyledProgressInfo>
                    {props.tempSpanHigh ? `${props.tempSpanLow}-${props.tempSpanHigh} daggrader: ` : `${props.tempSpanLow} daggrader: `}
                    <strong>{props.eventName}</strong>
                    <StyledProgressBar>
                        <StyledProgressBarFiller percentage={getProgressPercentage()} />
                        <Show when={getProgressPercentage() <= 93}>
                            <StyledTempSpanHighNumber>{props.tempSpanHigh}</StyledTempSpanHighNumber>
                        </Show>
                    </StyledProgressBar>
                    <StyledProgressNumberRow percentage={getProgressPercentage()}>
                        <StyledProgressNumber>{props.accumulatedDaytimeDegreesTemp}</StyledProgressNumber>
                    </StyledProgressNumberRow>
                </StyledProgressInfo>
                <StyledChevronWrapper>
                    <ChevronDownIcon />
                </StyledChevronWrapper>
            </StyledEventMainContent>

            <Show when={open()}>
                <StyledEventExpandableContent>
                    <Show when={props.text}>
                        <StyledTextHeading>Rekommenderade åtgärder:</StyledTextHeading>
                        <div innerHTML={props.text} />
                    </Show>
                    <Show when={props.showStrategies}>
                        <StyledVerticalSpace size={0.5} />
                        <StyledTextHeading>Befintliga strategier:</StyledTextHeading>
                        <Show when={strategies.loading}>
                            <SkeletonLoader numBlocks={2} />
                        </Show>
                        <For each={strategies()?.rows} fallback="Inga strategier hittades">
                            {(strategy: DaytimeDegreesEventStrategy) => <Link href={getLinkToStrategies()}>{strategyTitle(strategy)}</Link>}
                        </For>
                    </Show>
                    <Show when={props.products?.length}>
                        <StyledVerticalSpace size={0.5} />
                        <StyledTextHeading>Befintliga produkter:</StyledTextHeading>
                        <For each={props.products}>
                            {(product: DaytimeDegreesEventRelatedModel) => (
                                <Link href={`/kategorier/produkter/${product.slug}`}>{product.name}</Link>
                            )}
                        </For>
                    </Show>
                    <StyledVerticalSpace size={0.5} />
                    <Row justifyContent="center">
                        <Button variant="secondary" size="small" label="Dölj detta mål" onClick={() => props.hideEvent()} />
                    </Row>
                </StyledEventExpandableContent>
            </Show>
        </StyledDaytimeDegreesEvent>
    );
};
