import fertilizerSvg from '../../../assets/fertilizer.svg';
import {
    StyledRegCheckSearchResultsHeadline,
    StyledRegCheckSearchResultsText,
    StyledRegCheckSearchPromptImageContainer,
} from './regcheck-search-prompt.styles';

export const RegCheckSearchPrompt = () => (
    <div style={{ 'margin-top': '2rem' }}>
        <StyledRegCheckSearchPromptImageContainer>
            <img src={fertilizerSvg} width={128} height={128} alt="En gröda som växer" />
        </StyledRegCheckSearchPromptImageContainer>
        <StyledRegCheckSearchResultsHeadline>
            Med regkollen kontrollerar du snabbt om din produkt är godkänd att använda.
            <br />
            Ange produktnamn eller registreringsnummer i sökfältet.
        </StyledRegCheckSearchResultsHeadline>
        <StyledRegCheckSearchResultsText>
            Informationen är uppdaterad med den senaste informationen från Kemikalieinspektionen.
        </StyledRegCheckSearchResultsText>
    </div>
);
