import { gql } from 'graphql-request';

export const getContactsQuery = gql`
    query contacts($limit: Int, $offset: Int) {
        contacts: getContacts(limit: $limit, offset: $offset) {
            count
            rows {
                name
                email
                tel
                jobTitle
                imageSrc
                contactAreas {
                    priority
                    slug
                    name
                }
            }
        }
    }
`;
