export function isLocalStorageAvailable(): boolean {
    const test = 'ls-test';

    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}
