import { createUniqueId, Show } from 'solid-js';
import Checkbox from '../checkbox/checkbox';
import { CheckboxBarProps } from './checkbox-bar-types';
import { CheckboxComponent } from './checkbox-bar.styles';
import { StyledCheckboxLabel } from './checkbox-bar.styles'

const CheckboxBar = (props: CheckboxBarProps) => {
    const id = props.id || createUniqueId();

    return (
        <CheckboxComponent disabled={props.disabled} onClick={() => props.onChange(!props.checked)}>
            <Checkbox disabled={props.disabled} id={id} name={props.name} checked={props.checked} onChange={() => props.onChange(!props.checked)} />
            <Show when={props.label}>
                <StyledCheckboxLabel disabled={props.disabled}>{props.label}</StyledCheckboxLabel>
            </Show>
        </CheckboxComponent>
    );
};

export default CheckboxBar;
