import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';
import { StyleProps } from './icon-button-types';
import type { ColorName } from '../../style/style-variables';

const getTextColor = (variant: string, color: ColorName) => {
    if (variant === 'secondary') {
        return colors[color];
    }
    if (color === 'yellow') {
        return colors['red'];
    }
    return colors['white'];
};

export const StyledIconButton = styled.button<StyleProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(p) => (p.variant === 'primary' ? colors[p.color] : 'none')};
    color: ${(p) => (p.color === 'yellow' ? colors['red'] : colors.white)};
    border-radius: 10px;
    padding: ${(p) => (p.size === 'small' ? '0.25rem 0.75rem' : '0.75rem 1.5rem')};
    border: 0;
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};

    svg {
        fill: ${(p) => getTextColor(p.variant, p.color)};
    }
`;
