import { gql } from 'graphql-request';

export const getContactAreasQuery = gql`
    query contactAreas($limit: Int, $offset: Int) {
        contactAreas: getContactAreas(limit: $limit, offset: $offset) {
            count
            rows {
                slug
                name
                address
                children {
                    slug
                    name
                    address
                }
            }
        }
    }
`;
