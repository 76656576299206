import { Title } from '@solidjs/meta';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Container } from '../../components/layout/container/container';
import { Header } from '../../components/layout/header/header';
import { Screen } from '../../components/layout/screen/screen';
import { ContactsContainer } from './contacts-container';

export const ContactUsContainer = () => {
    return (
        <Screen>
            <Title>Kontakta oss - Gullvikspraktikan</Title>
            <BreadCrumbs links={[]} prompt="Kontakta oss" />
            <Header header="Kontakta oss" />
            <Container>
                <ContactsContainer />
            </Container>
        </Screen>
    );
};
