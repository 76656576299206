export const formatRows = (rows: any[]) => {
    const numColumns = rows.length;
    const numFields = rows[0].length;

    const formattedArray = Array.from({ length: numFields }, () => Array(numColumns).fill(''));

    for (let i = 0; i < numFields; i++) {
        for (let j = 0; j < numColumns; j++) {
            formattedArray[i][j] = rows[j][i];
        }
    }

    return formattedArray;
};
