import { Component, mergeProps } from 'solid-js';

import { ButtonProps } from './button.d';
import { StyledButton } from './button.styles';

const Button: Component<ButtonProps> = (componentProps: ButtonProps) => {
    const props = mergeProps({ variant: 'primary', size: 'medium' }, componentProps);

    return (
        <StyledButton size={props.size} variant={props.variant} id={props.id} onClick={() => props.onClick()} disabled={props.disabled}>
            {props.label}
        </StyledButton>
    );
};

export default Button;
