import { For } from 'solid-js';
import { Store } from 'solid-js/store';
import { FilterTypeStore, SearchApiResponse, SearchModel } from '../../search';
import {
    StyledDropdownList,
    StyledDropdownListContainer,
    StyledDropdownListHeader,
    StyledDropdownListInner,
    UnstyledButton,
} from './dropdown-list.styles';

export type DropdownItem = {
    heading: string;
    model: SearchModel;
    items: SearchApiResponse[];
};

type Props = {
    isLoading: boolean;
    filters: Store<FilterTypeStore>;
    onClick: (slug: string, model: SearchModel) => void;
};

export const DropdownList = (props: Props) => {
    const getDropdownItems = (): DropdownItem[] => {
        if (!props.filters.crop && !props.filters.treatment) {
            return [
                {
                    heading: 'Grödor',
                    model: 'crop' as SearchModel,
                    items: props.filters.crops,
                },
                {
                    heading: 'Mål',
                    model: 'treatment' as SearchModel,
                    items: props.filters.treatments,
                },
                {
                    heading: 'Produkter',
                    model: 'product' as SearchModel,
                    items: props.filters.products,
                },
                {
                    heading: 'Hittade via aktiv substans',
                    model: 'product' as SearchModel,
                    items: props.filters.activeIngredientProducts,
                },
            ];
        }

        if (props.filters.crop && !props.filters.treatment) {
            return [
                {
                    heading: 'Mål',
                    model: 'treatment' as SearchModel,
                    items: props.filters.strategies.treatments,
                },
            ];
        }

        if (props.filters.treatment && !props.filters.crop) {
            return [
                {
                    heading: 'Grödor',
                    model: 'crop' as SearchModel,
                    items: props.filters.strategies.crops,
                },
            ];
        }

        return [];
    };

    const filteredList = () => getDropdownItems().filter(({ items }) => items.length);

    return (
        <StyledDropdownListContainer isLoading={props.isLoading}>
            <StyledDropdownListInner>
                <For each={filteredList()}>
                    {({ heading, items, model }) => (
                        <>
                            <StyledDropdownListHeader>{heading}</StyledDropdownListHeader>
                            <StyledDropdownList>
                                <For each={items}>
                                    {({ name, slug }) => (
                                        <li>
                                            <UnstyledButton role="button" onClick={() => props.onClick(slug, model)}>
                                                {name}
                                            </UnstyledButton>
                                        </li>
                                    )}
                                </For>
                            </StyledDropdownList>
                        </>
                    )}
                </For>
            </StyledDropdownListInner>
        </StyledDropdownListContainer>
    );
};
