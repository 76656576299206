import { styled } from 'solid-styled-components';

export const StyledDaytimeDegreesEvent = styled.div`
    background-color: rgba(0, 0, 0, 0.03);
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #dedede;
`;

export const StyledProgressBar = styled.div`
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05) inset;
    height: 0.5rem;
    width: 100%;
    margin-top: 0.25rem;
    position: relative;
`;

export const StyledProgressBarFiller = styled.div<{ percentage: number }>`
    border-radius: 0.5rem;
    background: ${(p) => (p.percentage === 100 ? `#a4343a` : `linear-gradient(90deg, #fabb00 0%, rgba(164, 52, 58, 0.7) 100%), #ffbd00`)};
    height: 0.5rem;
    width: ${(p) => p.percentage}%;
`;

export const StyledProgressInfo = styled.div`
    width: 90%;
`;

export const StyledProgressNumberRow = styled.div<StyledProgressNumberRowProps>`
    width: ${(p) => p.percentage}%;
    position: relative;
`;
type StyledProgressNumberRowProps = {
    percentage: number;
};

export const StyledProgressNumber = styled.p`
    position: absolute;
    top: 4px;
    right: 0;
    color: #a4343a;
    font-size: 0.875rem;
`;

export const StyledTempSpanHighNumber = styled.p`
    position: absolute;
    top: 0.75rem;
    right: 0;
    color: #9a9a9a;
    font-size: 0.875rem;
`;

export const StyledChevronWrapper = styled.a`
    position: relative;
    top: 12px;
`;

export const StyledEventMainContent = styled.div<{ percentage: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-bottom: 1rem;
    opacity: ${(p) => (p.percentage === 100 ? 0.5 : 1)};
`;

export const StyledEventExpandableContent = styled.div`
    margin-top: 2rem;
`;

export const StyledTextHeading = styled.h4`
    font-weight: bold;
    margin-bottom: 0.75rem;
`;
