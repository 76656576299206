import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';

export const StyledCheckboxComponentPlain = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 0.5rem;
`;

export const StyledCheckboxInput = styled.input<StyledCheckboxInputProps>`
    appearance: none;
    margin: 0;
    font: inherit;
    color: ${(p) => (p.color ? p.color : colors.red)};
    width: 14px;
    height: 14px;
    border: 1px solid ${(p) => (p.color ? p.color : colors.red)};
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    background-color: ${ p => p.checked ? colors.red : colors.white};

    display: grid;
    place-content: center;
    cursor: pointer;

    &:before {
        content: '';
        width: 12px;
        height: 12px;
        clip-path: polygon(44% 77%, 88% 27%, 79% 20%, 43% 62%, 21% 46%, 14% 56%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${(p) => (p.color ? p.color : colors.white)};
        background-color: ${colors.red};
    }

    &:checked::before {
        transform: scale(1);
    }

    &:focus {
        /* outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em); */
    }

    &:disabled {
        color: #aaa;
        cursor: not-allowed;
    }
`;

export type StyledCheckboxInputProps = {
    checked?: boolean;
}
