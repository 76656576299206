import { Switch, Match } from 'solid-js';
import { BasicSelect } from './basic-select';
import { MultiSelect } from './multi-select';
import { SelectProps, SelectMultipleProps } from './select.types';
import { StyledSelectIndexWrapper } from './select.styles';

export const Select = (props: SelectProps | SelectMultipleProps) => (
    <StyledSelectIndexWrapper>
        <Switch fallback={<BasicSelect {...(props as SelectProps)} />}>
            <Match when={props.mode === 'basic'}>
                <BasicSelect {...(props as SelectProps)} />
            </Match>
            <Match when={props.mode === 'multiple'}>
                <MultiSelect {...(props as SelectMultipleProps)} />
            </Match>
        </Switch>
    </StyledSelectIndexWrapper>
);
