/// <reference types="@types/google.maps" />

import { onCleanup, onMount, Show, useContext } from 'solid-js';
import { SetStoreFunction } from 'solid-js/store';
import { isServer } from 'solid-js/web';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { LocalStorageDaytimeDegrees } from '../../containers/daytime-degrees/daytime-degrees-types';
import Button from '../button/button';
import { Input } from '../input/input';
import { Select } from '../select/select';
import { SelectOption } from '../select/select.types';
import { StyledCreateDaytimeDegreesForm, StyledDatepicker, StyledMap, StyledMapLabel } from './create-daytime-degrees.styles';
import { sub } from 'date-fns';
import AirDatepicker from 'air-datepicker';
import localeSv from 'air-datepicker/locale/sv';
import 'air-datepicker/air-datepicker.css';

type CropSelectOption = SelectOption & {
    dateTitle: string;
};

type CreateDaytimeDegreesFormProps = {
    crops: CropSelectOption[];
    formValues: LocalStorageDaytimeDegrees;
    updateForm: SetStoreFunction<LocalStorageDaytimeDegrees>;
    addDaytimeDegree: () => void;
};

export const CreateDaytimeDegreesForm = (props: CreateDaytimeDegreesFormProps) => {
    const appState = useContext(AppContext);

    let map: google.maps.Map;
    let marker: google.maps.Marker;

    onMount(() => {
        new AirDatepicker('#airdatepicker', {
            inline: true,
            locale: localeSv,
            minDate: sub(new Date(), { years: 1 }),
            maxDate: new Date(),
            disableNavWhenOutOfRange: true,
            isMobile: true,
            onSelect: (value) => props.updateForm({ date: value.formattedDate as string }),
        });

        const apiKey = appState.googleMapsApiKey;
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const initialLat = 55.990257;
            const initalLng = 13.595769;

            map = new google.maps.Map(document.getElementById('map') as HTMLDivElement, {
                center: { lat: initialLat, lng: initalLng },
                zoom: 8,
                mapTypeId: google.maps.MapTypeId.HYBRID, // Set the default map type to satellite
                mapTypeControl: false, // Remove the buttons that switch between map types
            });

            marker = new google.maps.Marker({
                map: map,
                draggable: true,
            });

            const initialLocation = new google.maps.LatLng(initialLat, initalLng);
            marker.setPosition(initialLocation);

            if (!isServer && window.location.protocol === 'https:') {
                try {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const { latitude, longitude } = position.coords;
                        const initialLocation = new google.maps.LatLng(latitude, longitude);
                        map.setCenter(initialLocation);
                        marker.setPosition(initialLocation);

                        props.updateForm({ lat: latitude, lng: longitude });
                    });
                } catch (error) {
                    // Chill for now
                }
            }

            marker.addListener('dragend', function () {
                const position = marker.getPosition();
                if (!position) return;

                props.updateForm({
                    lat: position.lat(),
                    lng: position.lng(),
                });
            });
        };

        onCleanup(() => {
            document.body.removeChild(script);
        });
    });

    const isSubmitDisabled = () => !props.formValues.cropSlug || !props.formValues.date || !props.formValues.lat || !props.formValues.lng;

    const getDateTitleForSelectedCrop = () => {
        const selectedCrop = props.crops.find((crop) => crop.value === props.formValues.cropSlug);
        return selectedCrop ? selectedCrop.dateTitle : 'Välj sådatum';
    };

    return (
        <StyledCreateDaytimeDegreesForm>
            <StyledMapLabel>
                <span>Ange position</span>
                Ange din position genom att dra den röda markören till rätt plats för att få korrekta daggrader baserat på lokala
                temperaturer. Din position används enbart för att ge dig relevanta uppgifter.
            </StyledMapLabel>
            <StyledMap id="map"></StyledMap>
            <Show when={props.crops.length}>
                <Select
                    label="Välj gröda"
                    mode="basic"
                    searchable
                    options={props.crops}
                    value={props.formValues.cropSlug}
                    onChange={(val: string) => {
                        props.updateForm({ cropSlug: val });
                    }}
                />
            </Show>

            <strong>{getDateTitleForSelectedCrop()}</strong>
            <StyledDatepicker>
                <input id="airdatepicker" value={props.formValues.date} />
            </StyledDatepicker>
            <Input value={props.formValues.note} label="Egen anteckning" onChange={(val: string) => props.updateForm({ note: val })} />
            <Button disabled={isSubmitDisabled()} onClick={props.addDaytimeDegree} label="Lägg till" />
        </StyledCreateDaytimeDegreesForm>
    );
};
