import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';

export const StyledCropTypeCategoriesContainer = styled.div`
    background: ${colors.red};
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledHeaderLogo = styled.div`
    text-align: center;
    margin-top: 3rem;

    img {
        width: auto;
        max-height: 100px;

        @media (min-width: 769px) {
            max-height: 120px;
        }
    }
`;

export const StyledCategoryLinksContainer = styled.div`
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 1rem;
    max-width: 1080px;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 768px) {
        max-width: 480px;
        flex-wrap: wrap;
    }

    @media (min-width: 769px) {
        box-sizing: inherit;
        grid-template-columns: repeat(4, 1fr);
        width: 95%;
    }
`;

export const StyledButtonLink = styled(Link)`
    margin: 1rem;
    position: relative;
    padding: 1.5rem;
    display: flex;
    background: ${colors.yellow};
    border-radius: 8px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    align-self: stretch;
    justify-content: center;
    font-size: 17px;
    overflow: hidden;

    @media (min-width: 769px) {
        font-size: 19px;
        box-sizing: border-box;
        width: 100%;
        margin: 2rem 0 0;
    }
`;

export const StyledStartPageHeadline = styled.h1`
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 2rem;

    @media (max-width: 300px) {
        font-size: 1.7rem;
    }

    @media (min-width: 769px) {
        font-size: 56px;
        font-weight: 700;
        margin: 2rem 0 4rem;
    }
`;

export const StyledTopContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const StyledStartPageStrategiesLink = styled.div`
    background-color: ${colors.yellow};
    margin: 1rem;
    border-radius: 8px;
    color: ${colors.black};
    padding: 1.5rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    align-self: stretch;

    @media (min-width: 769px) {
        box-sizing: border-box;
        width: 100%;
        margin: 2rem 0 0;
    }
`;

export const StyledStartPageStrategiesBottomLinkText = styled.div`
    color: ${colors.red};
    font-weight: 700;
    font-size: 32px;
    text-align: center;
`;

export const StyledStartPageStrategiesText = styled.div`
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: ${colors.red};
    text-align: center;

    @media (min-width: 769px) {
        font-size: 20px;
    }
`;

export const StyledStartPageLinksContainer = styled.div`
    width: 480px;

    @media (max-width: 480px) {
        width: 100%;
    }

    @media (min-width: 769px) {
        display: grid;
        width: 95%;
        max-width: 1080px;
        grid-template-columns: 1fr 2fr 1fr;
    }
`;

export const StyledDesktopOnlyDiv = styled.div`
    display: none;
    @media (min-width: 769px) {
        display: block;
    }
`;
