import { styled } from 'solid-styled-components';

export const StyledDaytimeDegreesCard = styled.div`
    background-color: white;
    border-radius: 12px;
    margin: 1rem;
    padding-top: 1.5rem;
    box-shadow: 0px 4px 4px 0px #e0e0e0;
    @media (min-width: 769px) {
        margin: 1rem 0;
    }
`;

export const StyledItemCardHeader = styled.h4`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`;

export const StyledLatLng = styled.div`
    padding-top: 0.5rem;
`;

export const StyledNoteRow = styled.div`
    display: flex;
    margin: 0.25rem 0 -0.75rem;
    gap: 0.5rem;
`;

export const StyledIconWrapper = styled.div`
    position: relative;
    top: 3px;
`;

export const StyledCardTopContent = styled.div`
    padding: 0 1rem 0.5rem;
`;

export const StyledErrorContent = styled.div`
    padding: 0.25rem 0.5rem;
    margin-left: 1rem;
    border-left: 5px solid #a4343a;
`;

export const StyledRemoveItemLink = styled.button`
    margin: -0.5rem auto 0;
    display: block;
    background: none;
    appearance: none;
    border: none;
    padding: 1rem;
    color: #808080;
    font-size: 1rem;
    cursor: pointer;
`;
