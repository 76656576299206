import { For, Show } from 'solid-js';
import type { PaginationProps } from './pagination-types.d';
import { StyledPagination, StyledPaginationItem } from './pagination.styles';

export const Pagination = (props: PaginationProps) => {
    const DOTS = '...';
    const siblingCount = 2;
    const totalPageCount = () => Math.ceil(props.totalRecords / props.perPage);

    const range = (start: number, end: number) => {
        const length = end - start + 1;
        /*
            Create an array of certain length and set the elements within it from
            start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const numLinks = () => {
        const pageCountTotal = totalPageCount();
        if (props.totalRecords === 0) {
            return [];
        }

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
        */

        if (totalPageNumbers >= pageCountTotal) {
            return range(1, pageCountTotal);
        }

        // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        const leftSiblingIndex = Math.max(props.currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(props.currentPage + siblingCount, pageCountTotal);

        // We do not show dots just when there is just one page number to be inserted between the extremes of sibling
        // and the page limits i.e 1 and totalPageCount.
        // Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < pageCountTotal - 2;

        const firstPageIndex = 1;

        // Case 2: No left dots to show, but rights dots to be shown
        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 1 + 2 * siblingCount;
            const leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, pageCountTotal];
        }

        // Case 3: No right dots to show, but left dots to be shown
        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount;
            const rightRange = range(pageCountTotal - rightItemCount + 1, pageCountTotal);

            return [firstPageIndex, DOTS, ...rightRange];
        }

        // Case 4: Both left and right dots to be shown
        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex);

            return [firstPageIndex, DOTS, ...middleRange, DOTS, pageCountTotal];
        }

        // Case 5: Show whole range
        return [...range(firstPageIndex, pageCountTotal)];
    };

    const onClickDotsLeft = () => {
        props.onChange(props.currentPage - 5);
    };

    const onClickDotsRight = () => {
        props.onChange(props.currentPage + 5);
    };

    const onClick = (symbol: number) => {
        if (props.currentPage !== symbol) {
            props.onChange(symbol);
        }
    };

    const proceed = (forward = true) => {
        if (forward && props.currentPage < range(1, totalPageCount()).length) {
            onClick(props.currentPage + 1);
            return;
        }

        if (!forward && props.currentPage > 1) {
            onClick(props.currentPage - 1);
        }
    };

    return (
        <Show when={props.totalRecords > props.perPage}>
            <StyledPagination>
                <StyledPaginationItem>
                    <a rel="nofollow" onClick={() => proceed(false)} class="icon-wrapper" classList={{ active: props.currentPage === 1 }}>
                        <svg viewBox="64 64 896 896" data-icon="left" width="1rem" height="1rem" fill="currentColor" aria-hidden="true">
                            <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                        </svg>
                    </a>
                </StyledPaginationItem>
                <For each={numLinks()}>
                    {(symbol, index) => {
                        if (symbol === DOTS) {
                            return (
                                <StyledPaginationItem>
                                    <a rel="nofollow" class="dots" onClick={() => (index() === 1 ? onClickDotsLeft() : onClickDotsRight())}>
                                        •••
                                    </a>
                                </StyledPaginationItem>
                            );
                        } else {
                            return (
                                <StyledPaginationItem>
                                    <a
                                        rel="nofollow"
                                        onClick={() => onClick(symbol as number)}
                                        classList={{ active: props.currentPage === symbol }}
                                    >
                                        {symbol}
                                    </a>
                                </StyledPaginationItem>
                            );
                        }
                    }}
                </For>
                <StyledPaginationItem>
                    <a
                        rel="nofollow"
                        onClick={() => proceed()}
                        class="icon-wrapper"
                        classList={{ active: props.currentPage === range(1, totalPageCount()).length }}
                    >
                        <svg viewBox="64 64 896 896" data-icon="right" width="1rem" height="1rem" fill="currentColor" aria-hidden="true">
                            <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                        </svg>
                    </a>
                </StyledPaginationItem>
            </StyledPagination>
        </Show>
    );
};
