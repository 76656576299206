import { createSignal, Show, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useNavigate } from '@solidjs/router';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getCropQuery, getCropsQuery, getCropTypeCategoriesQuery } from '../../graphql/queries/crops';
import { getProductsQuery } from '../../graphql/queries/products';
import { getTreatmentQuery, getTreatmentsQuery } from '../../graphql/queries/treatments';
import { getStrategiesSearchQuery } from '../../graphql/queries/strategies';
import type { Strategy } from '../strategies/strategies.d';
import type { Crop } from '../crops/crops.d';
import type { Treatment } from '../treatments/treatments.d';
import type { CropTypeCategory } from '../start/start-types';
import { filterRelatedModels, getAllProductsForStrategies, getFilteredStrategies, getRelatedModelsCount } from './utils';
import { DropdownList } from './components/dropdown-list/dropdown-list';
import { Results } from './components/results/results';
import { SearchBar } from './components/search-bar/search-bar';
import { StyledLoadingMessage, StyledSearchContainer, StyledSearchContainerInner } from './search.style';
import Button from '../button/button';
import { Row } from '../layout/row/row';
import { GrowthStages } from '../start/growth-stages/growth-stages';

const limit = 500;

export type SearchModel = 'crop' | 'treatment' | 'product';

export type SearchApiResponse = {
    name: string;
    slug: string;
};

export type FilterTypeStore = {
    searchTerm: string;
    secondarySearchTerm: string;
    crop: (Crop & { position: number }) | null;
    treatment: (Treatment & { position: number }) | null;
    crops: SearchApiResponse[];
    products: SearchApiResponse[];
    activeIngredientProducts: SearchApiResponse[];
    treatments: SearchApiResponse[];
    strategies: {
        results: Strategy[];
        crops: SearchApiResponse[];
        products: SearchApiResponse[];
        treatments: SearchApiResponse[];
        // The total number of unique crops, products and treatments returned
        // when we select a crop or treatment from the intial search
        totals: {
            crops: number;
            products: number;
            treatments: number;
        };
        // The resultant filtered strategies and products when both a crop AND a treatment are selected
        filtered: {
            strategies: Strategy[];
            products: SearchApiResponse[];
            pagination: {
                visible: boolean;
                limit: number;
                page: number;
            };
        };
    };
};

export const Search = () => {
    const navigate = useNavigate();
    const { isSearchOpen, graphQlClient, createCachedResource, setIsSearchOpen } = useContext(AppContext);
    const [cropTypeCategoriesData] = createCachedResource(getCropTypeCategoriesQuery);
    const [isLoading, setIsLoading] = createSignal(false);

    const getInitialState = () => ({
        searchTerm: '',
        secondarySearchTerm: '',
        crop: null,
        treatment: null,
        crops: [],
        products: [],
        activeIngredientProducts: [],
        treatments: [],
        strategies: {
            results: [],
            crops: [],
            products: [],
            treatments: [],
            totals: {
                crops: 0,
                products: 0,
                treatments: 0,
            },
            filtered: {
                strategies: [],
                products: [],
                pagination: {
                    visible: false,
                    limit: 10,
                    page: 1,
                },
            },
        },
    });
    const [filters, setFilters] = createStore<FilterTypeStore>(getInitialState());
    const categories = (): CropTypeCategory[] => cropTypeCategoriesData()?.cropTypeCategories?.rows || [];

    const removeSelection = (position = 1) => {
        if (filters.crop?.position === position) {
            removeCrop();
            return;
        }

        removeTreatment();
    };

    const removeCrop = () => {
        if (filters.crop?.position === 2) {
            setFilters({ crop: null });
            resetProducts();
            return;
        }

        const selectedTreatment = filters.treatment;
        setFilters(getInitialState());

        if (selectedTreatment) {
            selectedModalCallback(selectedTreatment.slug, 'treatment');
        }
    };

    const removeTreatment = () => {
        if (filters.treatment?.position === 2) {
            setFilters({ treatment: null });
            resetProducts();
            return;
        }

        const selectedCrop = filters.crop;
        setFilters(getInitialState());

        if (selectedCrop) {
            selectedModalCallback(selectedCrop.slug, 'crop');
        }
    };

    const resetProducts = () => {
        const filteredProducts = getAllProductsForStrategies(filters.strategies.results);
        setFilters('strategies', 'filtered', {
            strategies: [],
            products: filteredProducts,
            pagination: {
                visible: false,
                limit: 10,
                page: 1,
            },
        });

        secondarySearch();
    };

    const search = (term = '') => {
        setFilters({ searchTerm: term });

        if (term.length < 2) {
            setFilters('products', []);
            setFilters('treatments', []);
            setFilters('crops', []);
            return;
        }

        getProducts();
        getCrops();
        getTreatments();
    };

    const secondarySearch = async (term = '') => {
        if (term !== 'ALL') {
            setFilters({ secondarySearchTerm: term });

            // Reset the filters if the term is empty
            if (!term.length) {
                setFilters('strategies', 'products', []);
                setFilters('strategies', 'treatments', []);
                setFilters('strategies', 'crops', []);
                return;
            }
        }

        const { filteredCrops, filteredProducts, filteredTreatments } = filterRelatedModels(
            filters.strategies.results,
            filters.secondarySearchTerm,
            filters.crop ? 'crop' : 'treatment'
        );

        setFilters('strategies', 'products', filteredProducts);
        setFilters('strategies', 'treatments', filteredTreatments);
        setFilters('strategies', 'crops', filteredCrops);
    };

    const getProducts = async () => {
        const { products } = await graphQlClient(getProductsQuery, { searchTerm: filters.searchTerm, limit });
        const { products: activeIngredientProducts } = await graphQlClient(getProductsQuery, {
            ingredientSearchTerm: filters.searchTerm,
            limit,
        });

        setFilters({
            products: products.rows,
            activeIngredientProducts: activeIngredientProducts.rows.sort((a: SearchApiResponse, b: SearchApiResponse) =>
                a.name.localeCompare(b.name)
            ),
        });
    };

    const getCrops = async () => {
        const { crops } = await graphQlClient(getCropsQuery, { searchTerm: filters.searchTerm, limit });
        setFilters({ crops: crops.rows });
    };

    const getTreatments = async () => {
        const { treatments } = await graphQlClient(getTreatmentsQuery, { searchTerm: filters.searchTerm, limit });
        setFilters({ treatments: treatments.rows });
    };

    const setSelectedModel = async (slug: string, type: 'crop' | 'treatment' = 'crop', position = 1) => {
        setIsLoading(true);

        if (type === 'crop') {
            const { crop } = await graphQlClient(getCropQuery, { slug });
            setFilters({ crop: { ...crop, position } });
            setIsLoading(false);
            return;
        }

        const { treatment } = await graphQlClient(getTreatmentQuery, { slug });
        setFilters({ treatment: { ...treatment, position } });
        setIsLoading(false);
    };

    const getStrategies = async () => {
        setIsLoading(true);

        const params = {
            cropSlug: filters.crop?.slug || '',
            treatmentSlug: filters.treatment?.slug || '',
        };

        // Get the strategies via either crop or treatment slug
        const { strategies }: { strategies: Strategy[] } = await graphQlClient(getStrategiesSearchQuery, params);
        setFilters('strategies', 'results', strategies);

        // Count respective related models
        const { crops, treatments, products } = getRelatedModelsCount(strategies);
        setFilters('strategies', 'totals', { crops, products, treatments });

        // Get all related products and reset product pagination
        const filteredProducts = getAllProductsForStrategies(strategies);
        setFilters('strategies', 'filtered', {
            strategies: [],
            products: filteredProducts,
            pagination: {
                visible: false,
                limit: 10,
                page: 1,
            },
        });

        setIsLoading(false);
    };

    const getResultsFromStrategies = async () => {
        let filteredStrategies: Strategy[] = [];
        if (filters.crop && filters.treatment) {
            // Filter the strategies by both crop AND treatment
            filteredStrategies = getFilteredStrategies(filters.strategies.results, filters.crop, filters.treatment);
        }
        const filteredProducts = getAllProductsForStrategies(filteredStrategies);

        setFilters('strategies', 'filtered', {
            strategies: filteredStrategies,
            products: filteredProducts,
            pagination: {
                visible: true,
                limit: 10,
                page: 1,
            },
        });
    };

    const selectedModalCallback = async (slug: string, model: SearchModel) => {
        if (model === 'product') {
            exitSearch(`/kategorier/produkter/${slug}`);
            return;
        }

        if (isLoading()) {
            return;
        }

        const position = !filters.treatment && !filters.crop ? 1 : 2;
        await setSelectedModel(slug, model, position);

        if (position === 1) {
            getStrategies();
            return;
        }

        getResultsFromStrategies();
    };

    const exitSearch = (to?: string) => {
        setIsSearchOpen(false);
        setFilters(getInitialState());

        if (to) {
            navigate(to);
        }
    };

    return (
        <Show when={isSearchOpen()}>
            <StyledSearchContainer>
                <StyledSearchContainerInner>
                    <Row justifyContent="end">
                        <Button disabled={isLoading()} label="Stäng" onClick={() => exitSearch()} />
                    </Row>
                    <SearchBar
                        isLoading={isLoading()}
                        filters={filters}
                        setFilters={setFilters}
                        removeSelection={removeSelection}
                        search={search}
                        secondarySearch={secondarySearch}
                    />
                    <DropdownList isLoading={isLoading()} filters={filters} onClick={selectedModalCallback} />
                    <Show when={!isLoading()} fallback={<StyledLoadingMessage>Laddar resultat...</StyledLoadingMessage>}>
                        <Results filters={filters} setFilters={setFilters} categories={categories()} exitSearch={exitSearch} />
                    </Show>
                </StyledSearchContainerInner>
                <GrowthStages />
            </StyledSearchContainer>
        </Show>
    );
};
