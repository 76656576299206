import { Component, mergeProps } from 'solid-js';

import { LinkButtonProps } from './link-button-types';
import { StyledLinkButton } from './link-button.styles';

export const LinkButton: Component<LinkButtonProps> = (componentProps: LinkButtonProps) => {
    const props = mergeProps({ variant: 'primary', size: 'medium' }, componentProps);

    return (
        <StyledLinkButton
            href={props.href}
            size={props.size}
            variant={props.variant}
            id={props.id}
            disabled={props.disabled}
        >
            {props.label}
        </StyledLinkButton>
    );
};
