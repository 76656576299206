import { styled } from 'solid-styled-components';

export const StyledTreatmentWrapper = styled.div`
    position: relative;
    height: 100%;
`;

export const StyledButtonWrapper = styled.div`
    text-align: center;
    position: sticky;
    bottom: 1rem;

    @media (max-width: 768px) {
        left: 1rem;
        right: 1rem;
    }

    @media (min-width: 769px) {
        width: 50%;
        margin: 0 25%;
    }

    button {
        font-weight: 700;
        font-size: 18px;
        padding: 1rem;
        text-transform: uppercase;
        width: 100%;
        border-radius: 8px;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 20%);
    }
`;
