import { styled } from 'solid-styled-components';

export const StyledSearchContainer = styled.div<{ isLoading: boolean }>`
    display: flex;
    background-color: white;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #aaa;
    margin-top: 1rem;
    opacity: ${(p) => (p.isLoading ? 0.85 : 1)};
`;

export const StyledSearchInput = styled.input`
    all: unset;
    padding: 1rem;
    font-size: 16px;
    flex-grow: 1;
`;

export const StyledTag = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #8cc9f6;
    border: none;
    padding: 1.5rem 0.75rem;
    gap: 1rem;
    height: 30px;
    margin: 0.5rem 0 0.5rem 0.5rem;
    border-radius: 8px;
    color: #242424;
    font-size: 16px;
    cursor: pointer;

    &:nth-of-type(2) {
        margin-right: 0.75rem;
    }
`;
