import { createEffect, createSignal, Show, useContext } from 'solid-js';
import { Order } from '../../containers/orders/orders-container';
import Button from '../button/button';
import { Input } from '../input/input';
import { StyledWrapper } from './order-info.styles';
import { StyledVerticalSpace } from '../layout/layout.styles';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { updateOrderMutation } from '../../graphql/mutations/orders';

type Props = {
    order: Order | undefined;
};

export const OrderInfo = (props: Props) => {
    const { graphQlClient } = useContext(AppContext);
    const [signUrl, setSignUrl] = createSignal(props.order?.signIframeUrl);
    const [email, setEmail] = createSignal(props.order?.email || '');
    const [disabled, setDisabled] = createSignal(true);
    const [buttonText, setButtonText] = createSignal('Till kundförsäkran');

    const signOrder = async () => {
        setDisabled(true);
        setButtonText('Var god vänta...');

        if (!props.order?.email) {
            await createSimpleSignDocument();
        }

        setDisabled(false);
        if (signUrl()) {
            window.open(signUrl(), '_self');
        }
    };

    const createSimpleSignDocument = async () => {
        const response = await graphQlClient(updateOrderMutation, {
            uuid: props?.order?.uuid,
            email: email(),
        });
        setSignUrl(response.updateOrder.signIframeUrl);
    };

    createEffect(() => {
        setDisabled(email()?.match(/^[\w-.]+@([\w-]+\.)+[\w-.]{2,}$/) === null);
    });

    return (
        <StyledWrapper>
            <Show when={props.order} fallback={<p>Laddar...</p>}>
                <div style={{ 'padding-top': '1rem' }}>
                    <h2>Din kundförsäkran från Gullviks behöver signeras</h2>
                    <p>Du har en kundförsäkran som behöver signeras för ordernummer {props.order?.orderNumber}.</p>
                    <Show when={!props.order?.email}>
                        <p>
                            För att kunna slutföra din signering behöver du fylla i din e-postadress. Detta för att du ska kunna motta en
                            kopia på din kundföräkran.
                        </p>
                        <div style={{ 'max-width': '420px' }}>
                            <Input label="E-postaddress *" type="email" value={email()} onChange={(v) => setEmail(v)} />
                        </div>
                        <StyledVerticalSpace size={1} />
                    </Show>
                    <Button disabled={disabled()} label={buttonText()} onClick={signOrder} />
                </div>
            </Show>
        </StyledWrapper>
    );
};
