import { gql } from 'graphql-request';

export const getOrderQuery = gql`
    query order($uuid: String!) {
        order: getOrder(uuid: $uuid) {
            uuid
            firstName
            lastName
            phone
            signIframeUrl
            orderNumber
            socialSecurityNumber
            smsSent
            signed
            email
        }
    }
`;
