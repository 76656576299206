import { styled } from 'solid-styled-components';

export const StyledResultsContainer = styled.div`
    color: white;

    @media (min-width: 769px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    a {
        color: white;
        text-decoration: underline;
    }
`;

export const StyledResultsInner = styled.div`
    padding: 1rem 0;

    @media (min-width: 769px) {
        padding: 1rem;
    }
`;

export const StyledProductsList = styled.ul`
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 0.5rem;
    }
`;
