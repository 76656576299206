import { createEffect, For, lazy, Show, useContext } from 'solid-js';
import { FlashPlacements } from '@24hr/gullvikspraktikan-entities';
import { createStore } from 'solid-js/store';
import { useLocation } from '@solidjs/router';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getFlashesQuery } from '../../graphql/queries/flash';
import { StyledFlashContainer } from '../../components/flash/flash.styles';
import { Flash as FlashComponent } from '../../components/flash/flash';
import type { Flash } from '../../components/flash/flash.d';
import { CarouselItem } from '../../components/carousel/carousel-item';

const LazyCarousel = lazy(() => import('../../components/carousel/carousel'));

export const FlashContainer = () => {
    const { graphQlClient } = useContext(AppContext);
    const location = useLocation();
    const [flashes, setFlashes] = createStore<Flash[]>([]);

    const getPlacementFromPathname = (pathname: string) => {
        switch (pathname) {
            case '/':
                return FlashPlacements.Start;
            case '/kategorier':
                return FlashPlacements.ProductCategory;
            case '/strategier/lantbruk':
                return FlashPlacements.Farming;
            case '/strategier/skog':
                return FlashPlacements.Forestry;
            case '/strategier/tradgard':
                return FlashPlacements.Gardening;
        }

        return null;
    };

    createEffect(async () => {
        const placement = getPlacementFromPathname(location.pathname);
        if (!placement) {
            return setFlashes([]);
        }

        const response = await graphQlClient(getFlashesQuery, { placement, limit: 5, offset: 0, activeOnly: true });
        const items = response?.flashes?.rows || [];

        if (JSON.stringify(items) !== JSON.stringify(flashes)) {
            setFlashes(response?.flashes?.rows || []);
        }
    });

    return (
        <Show when={flashes.length}>
            <StyledFlashContainer>
                <LazyCarousel numItems={flashes.length} showNavigation>
                    <For each={flashes}>
                        {(flash) => (
                            <CarouselItem>
                                <FlashComponent flash={flash} />
                            </CarouselItem>
                        )}
                    </For>
                </LazyCarousel>
            </StyledFlashContainer>
        </Show>
    );
};
