import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import { colorsRgb, colors } from '../../style/style-variables';

export const StyledLink = styled.div`
    display: block;
    color: ${colors.black};
    background: rgba(231, 239, 180, 0.6);
    background: rgba(${colorsRgb.yellow}, 0.2);
    border-radius: 8px;
    margin-bottom: 0.5rem;
    font-size: 18px;

    a,
    a:visited {
        padding: 1rem 0.5rem 1rem 1rem;
        display: flex;
        color: #222;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
    }
`;

export const StyledLinkTag = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
