import { styled } from "solid-styled-components";
import { colors } from "../../style/style-variables";

export const StyledDesktopHeader = styled.div`
    height: 120px;
    background-color: ${colors.red};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    z-index: 1;

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const StyledDesktopHeaderText = styled.div`
    font-weight: 500;
    font-size: 28px;
    color: ${colors.white};
`;

export const StyledHeaderLogo = styled.div`
    text-align: center;
`;

export const StyledDesktopHeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledDesktopHeaderRight = styled.div``;

export const StyledLoginButton = styled.div`
    font-size: 16px;
    padding: 1rem 3rem;
    border: 1px solid rgba(255,255,255,0.2);
    color: white;
    border-radius: 8px;
    cursor: pointer;
`;
