import { styled } from 'solid-styled-components';

export const StyledDaytimeDegreesListEmptyMessage = styled.div`
    background-color: white;
    border-radius: 12px;
    margin: 1rem;
    padding: 2.25rem 1.25rem;
    box-shadow: 0px 4px 4px 0px #e0e0e0;
    text-align: center;

    @media (min-width: 769px) {
        margin: 1rem 0;
    }
`;

export const StyledButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    button {
        font-weight: 700;
        padding: 0.75rem 1rem;
        border-radius: 8px;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 20%);
    }
`;
