import { styled } from 'solid-styled-components';
import { colors } from '../../../style/style-variables';

export const StyledTags = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

export const StyledTag = styled.span<StyledTagProps>`
    padding: 0.5rem;
    color: ${(p) => (p.color && p.color === 'green' ? '#f1f5d2' : colors.black)};
    background: ${(p) => (p.color ? colors[p.color] : '#607f0b')};
    line-height: 1;
    border-radius: 50px;
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: 14px; 
`;

export type StyledTagProps = {
    color?: 'green' | 'yellow' | 'lightblue' | 'white';
}
