export const colors = {
    red: '#A4343A',
    blue: '#22529C',
    green: '#607f0b',
    yellow: '#FABB00',
    white: '#FFFFFF',
    black: '#222222',
    gray: '#c3c3c3',
    lightblue: '#A6EAFF',
    lightgray: '#f0f0f0',
};

export type ColorName = 'red' | 'blue' | 'green' | 'yellow' | 'white' | 'black' | 'gray';

export const colorsRgb = {
    yellow: '250,187,0',
}
