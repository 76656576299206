import { ListFilterArgs } from '../../containers/upma/upma-types';
import { Input } from '../input/input';
import { StyledVerticalSpace } from '../layout/layout.styles';
import { UpmaProduct } from '../products/product-types';
import { Select } from '../select/select';

type UpmaControlsProps = {
    products: UpmaProduct[];
    currentFilters: ListFilterArgs;
    onFilterUpdate: (args: Partial<ListFilterArgs>, scrollToTop?: boolean) => unknown;
};

export const UpmaControls = (props: UpmaControlsProps) => {
    const productSelectOptions = () =>
        props.products.map((product: UpmaProduct) => ({
            value: product.slug,
            text: product.name,
        }));

    return (
        <>
            <Select
                searchable
                mode="multiple"
                label="Välj produkt"
                value={props.currentFilters.productSlugs}
                options={productSelectOptions()}
                onChange={(productSlugs: string[]) => props.onFilterUpdate({ productSlugs, offset: 0 })}
            />
            <StyledVerticalSpace size={1} />
            <Input
                placeholder="Sök..."
                value={props.currentFilters.searchTerm}
                onChange={(searchTerm: string) => props.onFilterUpdate({ searchTerm, offset: 0 })}
            />
            <StyledVerticalSpace size={1} />
            <Select
                mode="basic"
                value={props.currentFilters.activeOnly ? 'active' : 'all'}
                options={[
                    {
                        value: 'active',
                        text: 'Visa enbart gällande beslut',
                    },
                    {
                        value: 'all',
                        text: 'Visa alla',
                    },
                ]}
                onChange={(value: string) => props.onFilterUpdate({ activeOnly: value === 'active', offset: 0 })}
            />
        </>
    );
};
