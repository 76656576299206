/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GraphQLClient } from 'graphql-request';
import { hydrate } from 'solid-js/web';
import { AppContextProvider } from '../isomorphic/app-context-provider/app-context-provider';
import { App } from '../isomorphic/app/app';
import { Router } from '@solidjs/router';
import { AppState } from '../isomorphic/types/app-state';
import { MetaProvider } from '@solidjs/meta';
// Get the appstate from the SSR
const appState: AppState = JSON.parse(document.getElementById('_appstate')!.innerText);

const client = new GraphQLClient('/api');

const graphqlClient = (query: string, variables: any) => client.request(query, variables);

hydrate(
    () => (
        <AppContextProvider
            imagesServiceUrl={appState.settings.imagesServiceUrl}
            client={graphqlClient}
            cache={appState.cache}
            googleMapsApiKey={appState.settings.googleMapsApiKey}
        >
            <Router url={window.location.pathname}>
                <MetaProvider>
                    <App />
                </MetaProvider>
            </Router>
        </AppContextProvider>
    ),
    // @ts-ignore
    document.getElementById('root')
);
