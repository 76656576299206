import {styled} from 'solid-styled-components';

export const StyledRegCheckSearchContainer = styled.div`
    display: flex;
    background-color: white;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #aaa;
    margin-top: 1rem;
`;

export const StyledRegCheckSearchInput = styled.input`
    all: unset;
    padding: 1rem;
    font-size: 16px;
    flex-grow: 1;
`;

export const StyledRegCheckSearchContainerWrapper = styled.div`
    background-color: #a4343a;
    margin: -2rem -1rem -1rem -1rem;
    padding: 1rem;
`;
