import { styled } from 'solid-styled-components';

export const StyledDocumentSignedBox = styled.div`
    background: #fabb00;
    position: fixed;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    padding: 1rem;
    border-radius: 5px;
    color: #222;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    text-align: center;

    p {
        font-weight: bold;
        font-size: 1.2rem;
    }
`;
