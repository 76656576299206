import { NavLink } from '@solidjs/router';
import { StyledDesktopCategoriesMenu, StyledDesktopCategoriesMenuLink } from './desktop-categories-menu.styles';

export const DesktopCategoriesMenu = () => (
    <StyledDesktopCategoriesMenu>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/strategier/lantbruk">Lantbruk</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/strategier/skog">Skog</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/strategier/tradgard">Trädgård</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/kategorier">Produkter</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/regkoll">Regkollen</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/daggrader">Daggrader</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/blandningstabeller">Blandningstabeller</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/upma">UPMA</NavLink>
        </StyledDesktopCategoriesMenuLink>
        <StyledDesktopCategoriesMenuLink>
            <NavLink href="/kontakta-oss">Kontakta oss</NavLink>
        </StyledDesktopCategoriesMenuLink>
    </StyledDesktopCategoriesMenu>
);
