import { createSignal, Show, For, onMount } from 'solid-js';
import { SelectOption, SelectProps } from './select.types';
import { SearchField } from './search-field/search-field';
import {
    StyledSelectComponent,
    StyledSelectWrapper,
    StyledSelectField,
    StyledSelectOptions,
    StyledSelectOption,
    StyledLabel,
    StyledSelectValue,
    StyledSelectValuePlaceholder,
    StyledArrowIcon
} from './select.styles';

export const BasicSelect = (props: SelectProps) => {
    let selectWrapper: any;

    onMount(() => {
        document.addEventListener('click', handleClickOutside, false);
    });

    const handleClickOutside = (e: any) => {
        const inside = selectWrapper.contains(e.target);
        if(!inside) {
            setOpen(false);
            setVisibleOptions(props.options);
        }
    }

    const [open, setOpen] = createSignal(false);
    const [visibleOptions, setVisibleOptions] = createSignal(props.options);
    const [searchFieldQuery,setSearchFieldQuery] = createSignal('');

    const getCurrentlySelectedOptionText = () => {
        const currentlySelectedOption = props.options.find(
            (option: SelectOption) => option.value === props.value
        );
        return currentlySelectedOption ? currentlySelectedOption.text : ' -- Välj --';
    }

    const searchableSelectValueFieldContent = () => {
        if (open()) {
            return (
                <SearchField
                    options={props.options}
                    reportVisibleOptions={(visibleOptions: SelectOption[]) => {
                        setVisibleOptions(visibleOptions);
                    }}
                    placeholder={getCurrentlySelectedOptionText()}
                    onSearchFieldChange={(val: string) => setSearchFieldQuery(val)}
                    value={searchFieldQuery()}
                />
            );
        } else {
            return (
                <StyledSelectValue>
                    <Show when={getCurrentlySelectedOptionText()} fallback={<StyledSelectValuePlaceholder />}>
                        <span innerHTML={getCurrentlySelectedOptionText()} />
                    </Show>
                </StyledSelectValue>
            );
        }
    };

    const handleOptionClick = (val: string, isDisabled = false) => {
        if (isDisabled) {
            return;
        }

        props.onChange(val);
        setVisibleOptions(props.options);
        setSearchFieldQuery('');
        setOpen(false);
    };

    return (
        <StyledSelectWrapper ref={selectWrapper}>
            <Show when={props.label}>
                <StyledLabel>
                    <span>{props.label}</span>
                </StyledLabel>
            </Show>
            <StyledSelectComponent>
                <StyledSelectField onClick={() => setOpen(!open())} disabled={props.disabled} searchable={props.searchable}>
                    <>
                        {props.searchable ? (
                            searchableSelectValueFieldContent()
                        ) : (
                            <StyledSelectValue>
                                <Show when={getCurrentlySelectedOptionText()} fallback={<StyledSelectValuePlaceholder />}>
                                    <span innerHTML={getCurrentlySelectedOptionText()} />
                                </Show>
                            </StyledSelectValue>
                        )}
                        <StyledArrowIcon>
                            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.30742 4.43048L10.4708 0.311734C10.891 -0.103911 11.5722 -0.103911 11.9923 0.311734C12.4125 0.72738 12.4125 1.40128 11.9923 1.81692L7.06817 6.68827C6.64801 7.10392 5.9668 7.10391 5.54665 6.68826L0.622572 1.81691C0.202422 1.40126 0.20243 0.727367 0.622588 0.311726C1.04275 -0.103916 1.72395 -0.103908 2.1441 0.311742L6.30742 4.43048Z"
                                    fill="#151515"
                                />
                            </svg>
                        </StyledArrowIcon>
                    </>
                </StyledSelectField>
                <Show when={open()}>
                    <StyledSelectOptions>
                        <For each={visibleOptions()} fallback={<div>No options</div>}>
                            {({ disabled, value, text }) => (
                                <StyledSelectOption disabled={disabled} onClick={() => handleOptionClick(value, disabled)}>
                                    <span innerHTML={text} />
                                </StyledSelectOption>
                            )}
                        </For>
                    </StyledSelectOptions>
                </Show>
            </StyledSelectComponent>
        </StyledSelectWrapper>
    );
};
