export const parseErrorMessage = (error: any): string => {
    if (!Array.isArray(error?.response?.errors)) {
        return 'Ett fel uppstod!';
    }

    return error.response.errors
        ?.map(({ message = '' }) => message)
        .filter((m: string) => m)
        .join(', ');
};
