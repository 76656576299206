import { styled } from 'solid-styled-components';

export const StyledAdsContainer = styled.div`
    &:not(:empty) {
        margin-top: 1rem;
    }

    @media (min-width: 960px) {
        justify-content: center;
        display: flex;
        gap: 1rem;
    }
`;

export const StyledAd = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 959px) {
        &:nth-of-type(2) {
            margin-top: 1rem;
        }
    }

    img {
        width: 419px;
        max-width: 100%;
    }
`;
