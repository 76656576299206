import { createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { DaytimeDegreesItem } from '../../components/daytime-degrees/daytime-degrees-item';
import { CropTypeCategory } from '../../components/start/start-types';
import { Treatment } from '../../components/treatments/treatments.d';
import { CropType } from '../../components/crop-types/crop-types.d';
import { getCropQuery } from '../../graphql/queries/crops';
import { getDaytimeDegreesQuery, getDaytimeDegreesWeatherData } from '../../graphql/queries/daytime-degrees';
import {
    DaytimeDegreesEvent,
    DaytimeDegreesEventRelatedModel,
    DaytimeDegreesItemEventType,
    LocalStorageDaytimeDegrees,
} from './daytime-degrees-types';
import { getFromLocalStorage, updateLocalStorageItem } from './dd-localstorage';

export type DaytimeDegreesItemContainerProps = LocalStorageDaytimeDegrees & {
    index: number;
    categories: CropTypeCategory[];
    treatments: Treatment[];
    daytimeDegreesCrops: {
        daytimeDegreesCrops: {
            count: number;
            rows: {
                crop: CropType;
                dateTitle: string;
            }[];
        };
    };
    removeItem: () => void;
};

const getKeyFromArray = (array: string[]) => array.join('__');

export const DaytimeDegreesItemContainer = (props: DaytimeDegreesItemContainerProps) => {
    const { createCachedResource } = useContext(AppContext);
    const [events, setEvents] = createSignal<DaytimeDegreesItemEventType[]>([]);
    const [error, setError] = createSignal<null | string>(null);

    const [daytimeDegrees] = createCachedResource(getDaytimeDegreesQuery, { cropSlug: props.cropSlug });
    const [crop] = createCachedResource(getCropQuery, { slug: props.cropSlug });
    const [weatherData] = createCachedResource(getDaytimeDegreesWeatherData, { lat: props.lat, lng: props.lng, startDate: props.date });

    const getAccumulatedDaytimeDegreesTempForEvent = (baseTemp: number) => {
        const accumulated = weatherData()?.weatherData?.data?.reduce(
            (total: number, point: { average: number }) => (point.average > baseTemp ? total + (point.average - baseTemp) : total),
            0
        );

        return Math.round(accumulated || 0);
    };

    const dateTitle = () => {
        const ddCrop = props.daytimeDegreesCrops?.daytimeDegreesCrops?.rows?.find(({ crop }) => crop.slug === props.cropSlug);
        return ddCrop?.dateTitle || 'Sådatum';
    };

    const hideEvent = (treatments: DaytimeDegreesEventRelatedModel[]) => {
        if (!window.confirm('Är du säker på att du vill dölja målet?')) {
            return;
        }

        const storage = getFromLocalStorage();
        const hiddenTreatments = new Set(storage[props.index].hiddenTreatments);

        const events: DaytimeDegreesEvent[] = daytimeDegrees()?.daytimeDegrees?.events;
        const event: DaytimeDegreesEvent | undefined = events.find(
            (event) => JSON.stringify(event.treatments) === JSON.stringify(treatments)
        );

        if (!event) {
            return;
        }

        const key = getKeyFromArray(event.treatments.map(({ slug }) => slug));
        hiddenTreatments.add(key);

        updateLocalStorageItem(props.index, { hiddenTreatments: [...hiddenTreatments] });
        setEvents(getEvents());
    };

    createEffect(() => {
        setEvents(getEvents());
        if (weatherData()?.weatherData?.error) {
            setError(weatherData().weatherData.error);
        }
    });

    const latLng = () => {
        const storage = getFromLocalStorage();
        const storageEvent = storage.find((e) => e.cropSlug === props.cropSlug);
        return storageEvent ? { lat: storageEvent.lat, lng: storageEvent.lng } : null;
    };

    const isLoading = () => !daytimeDegrees()?.daytimeDegrees || !weatherData()?.weatherData;

    const getEventName = ({ treatments }: DaytimeDegreesEvent) =>
        treatments
            .map(({ name }) => name)
            .sort((a, b) => a.localeCompare(b))
            .join(', ') || 'N/A';

    const getEvents = () => {
        const storage = getFromLocalStorage();

        if (!weatherData()?.weatherData?.data?.length) {
            return [];
        }

        return (
            daytimeDegrees()
                ?.daytimeDegrees?.events?.filter((event: DaytimeDegreesEvent) => {
                    const storageEvent = storage.find((e) => e.cropSlug === props.cropSlug);
                    if (!storageEvent) {
                        return true;
                    }

                    const treatmentKey = getKeyFromArray(event.treatments.map(({ slug }) => slug));
                    // Check if the user has hidden the current event's treatment.
                    return !storageEvent.hiddenTreatments.find((key) => key === treatmentKey);
                })
                .map((event: DaytimeDegreesEvent) => ({
                    accumulatedDaytimeDegreesTemp: getAccumulatedDaytimeDegreesTempForEvent(event.baseTemp),
                    tempSpanLow: event.tempSpanLow,
                    tempSpanHigh: event.tempSpanHigh,
                    eventName: getEventName(event),
                    text: event.text,
                    cropSlug: props.cropSlug,
                    treatments: event.treatments,
                    products: event.products,
                    showStrategies: event.showStrategies,
                })) ?? []
        );
    };

    return (
        <DaytimeDegreesItem
            crop={crop()?.crop}
            sowingDate={props.date}
            note={props.note}
            events={events()}
            error={error()}
            isLoading={isLoading()}
            hideEvent={hideEvent}
            dateTitle={dateTitle()}
            hiddenTreatments={props.hiddenTreatments}
            removeItem={props.removeItem}
            categories={props.categories}
            treatments={props.treatments}
            latLng={latLng()}
        />
    );
};
