export const NoteIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="f-chat 1" clip-path="url(#clip0_2705_1794)">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M14 0.5H2C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V11C0.5 11.3978 0.658035 11.7794 0.93934 12.0607C1.22064 12.342 1.60218 12.5 2 12.5H5.5L8 15.5L10.5 12.5H14C14.3978 12.5 14.7794 12.342 15.0607 12.0607C15.342 11.7794 15.5 11.3978 15.5 11V2C15.5 1.60218 15.342 1.22064 15.0607 0.93934C14.7794 0.658035 14.3978 0.5 14 0.5Z"
                        stroke="#212121"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path id="Vector_2" d="M3.5 4.5H12.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_3" d="M3.5 8.5H12.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2705_1794">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
