import { styled } from "solid-styled-components";

export const StyledAppRoot = styled.div`
    img {
        max-width: 100%;
    }
`;

export const StyledAppInner = styled.div`
    background: #f2f2f2;
`;
