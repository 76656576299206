import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Start } from '../../components/start/start';
import { Screen } from '../../components/layout/screen/screen';
import { getCropTypeCategoriesQuery } from '../../graphql/queries/crops';

export const StartContainer = () => {
    const { createCachedResource } = useContext(AppContext);
    const [cropTypeCategoriesData] = createCachedResource(getCropTypeCategoriesQuery);

    return (
        <Screen>
            <Start cropTypeCategoriesData={cropTypeCategoriesData} />
        </Screen>
    );
};
