import { For, Show } from 'solid-js';
import { TransitionGroup } from 'solid-transition-group';
import fertilizerSvg from '../../../assets/fertilizer.svg';
import { MatchingProduct } from '../../containers/regcheck/regcheck-container';
import {
    StyledRegCheckSearchPromptImageContainer,
    StyledRegCheckSearchResultsText,
} from '../regcheck-search-prompt/regcheck-search-prompt.styles';
import { RegCheckSearchResultsItem, RegistrationNumberCheckItemType } from './regcheck-search-results-item/regcheck-search-results-item';
import { StyledRegCheckSearchNoResults, StyledRegCheckSearchResults } from './regcheck-search-results.styles';

type Props = {
    registrationNumbers: RegistrationNumberCheckItemType[];
    matchedProducts: MatchingProduct[];
    searchTerm: string;
};

export const RegCheckSearchResults = (props: Props) => (
    <Show
        when={props.registrationNumbers?.length}
        fallback={
            <StyledRegCheckSearchNoResults>
                <StyledRegCheckSearchPromptImageContainer>
                    <img src={fertilizerSvg} width={128} height={128} alt="En gröda som växer"></img>
                </StyledRegCheckSearchPromptImageContainer>
                <StyledRegCheckSearchResultsText style={{ 'padding-bottom': '1rem' }}>
                    Inga resultat för <strong>{props.searchTerm}</strong>
                </StyledRegCheckSearchResultsText>
            </StyledRegCheckSearchNoResults>
        }
    >
        <StyledRegCheckSearchResults>
            <TransitionGroup name="regkoll-list">
                <For each={props.registrationNumbers}>
                    {(item) => <RegCheckSearchResultsItem item={item} matchedProducts={props.matchedProducts} />}
                </For>
            </TransitionGroup>
        </StyledRegCheckSearchResults>
    </Show>
);
