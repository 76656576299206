import { Show } from 'solid-js';
import { ContainerProps } from './container.d';
import { StyledContainerWrapper, StyledContainer, StyledHeader } from './container.style';

export const Container = (props: ContainerProps) => (
    <StyledContainerWrapper>
        <StyledContainer>
            <Show when={props.header}>
                <StyledHeader>
                    <>{props.header}</>
                </StyledHeader>
            </Show>
            {props.children}
        </StyledContainer>
    </StyledContainerWrapper>
);
