import { For, useContext } from 'solid-js';
import { CropTypeCategoriesProps, ApiResponse } from './start-types';
import { ImageLinkCard } from '../image-link-card/image-link-card';
import { getImageUrlByIndex, getBottomBackgroundColor } from './helpers';
import LogoImage from '/client/assets/images/logo.png';
import {
    StyledCategoryLinksContainer,
    StyledCropTypeCategoriesContainer,
    StyledHeaderLogo,
    StyledStartPageHeadline,
    StyledTopContent,
    StyledButtonLink,
    StyledStartPageLinksContainer,
    StyledDesktopOnlyDiv,
} from './start.styles';
import { GrowthStages } from './growth-stages/growth-stages';
import { Disclaimer } from './disclaimer';
import { Shiny } from './shiny';
import { DocumentSignedNotice } from './document-signed-notice';
import { AppContext } from '../../app-context-provider/app-context-provider';

export const Start = (props: CropTypeCategoriesProps) => {
    const { setIsSearchOpen } = useContext(AppContext);
    const categories = (): ApiResponse => props.cropTypeCategoriesData()?.cropTypeCategories;

    return (
        <StyledCropTypeCategoriesContainer>
            <StyledTopContent>
                <StyledHeaderLogo>
                    <img src={LogoImage} alt="Gullviks logotyp" height={240} width={404} />
                </StyledHeaderLogo>

                <StyledStartPageHeadline>Gullvikspraktikan</StyledStartPageHeadline>

                <StyledCategoryLinksContainer>
                    <For each={categories()?.rows}>
                        {(term, index) => (
                            <ImageLinkCard
                                name={term.name}
                                href={`/strategier/${term.slug}`}
                                imageUrl={getImageUrlByIndex(index())}
                                bottomBackgroundColor={getBottomBackgroundColor(index())}
                            />
                        )}
                    </For>
                    <ImageLinkCard
                        imageUrl={getImageUrlByIndex(3)}
                        name="Produkter"
                        href="/kategorier"
                        bottomBackgroundColor={getBottomBackgroundColor(3)}
                    />
                </StyledCategoryLinksContainer>

                <StyledStartPageLinksContainer>
                    <StyledDesktopOnlyDiv />
                    <div>
                        <StyledButtonLink
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsSearchOpen(true);
                            }}
                        >
                            Sök gröda, mål eller produkt
                        </StyledButtonLink>
                        <StyledButtonLink href="/regkoll">
                            Regkollen<Shiny>ny</Shiny>
                        </StyledButtonLink>
                        <StyledButtonLink href="/daggrader">Daggrader</StyledButtonLink>
                        <StyledButtonLink href="/blandningstabeller">Blandningstabeller</StyledButtonLink>
                        <StyledButtonLink href="/upma">UPMA</StyledButtonLink>

                        <StyledButtonLink href="/kontakta-oss">Kontakta Oss</StyledButtonLink>
                        <Disclaimer />
                    </div>
                    <StyledDesktopOnlyDiv />
                </StyledStartPageLinksContainer>
            </StyledTopContent>
            <GrowthStages />
            <DocumentSignedNotice />
        </StyledCropTypeCategoriesContainer>
    );
};
