import { Title } from '@solidjs/meta';
import { useParams } from '@solidjs/router';
import { Show, createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Container } from '../../components/layout/container/container';
import { Header } from '../../components/layout/header/header';
import { Screen } from '../../components/layout/screen/screen';
import { getOrderQuery } from '../../graphql/queries/orders';
import { OrderInfo } from '../../components/orders/order-info';
import { StyledVerticalSpace } from '../../components/layout/layout.styles';

export type Order = {
    uuid: string;
    createdAt?: Date;
    accessToken: string;
    firstName: string;
    lastName: string | null;
    phone: string;
    signIframeUrl: string;
    orderNumber: string;
    socialSecurityNumber?: string | null;
    orderDocument: string | null;
    smsSent: Date | null;
    signed: Date | null;
    email: string | null;
};

export const OrdersContainer = () => {
    const { graphQlClient } = useContext(AppContext);
    const params = useParams<{ uuid: string }>();
    const [order, setOrder] = createSignal<Order>();
    const [is404, setIs404] = createSignal(false);

    const fetch = async () => {
        if (!params.uuid) {
            return;
        }

        const response = await graphQlClient(getOrderQuery, { uuid: params.uuid });

        if (response.order) {
            setOrder(response.order);
        } else {
            setIs404(true);
        }
    };

    createEffect(async () => {
        await fetch();
        // redirect to /thanks if order is signed
        if (order()?.signed) {
            window.location.href = '/orders/thanks';
        }
    });

    return (
        <Screen>
            <Title>Kundförsäkran</Title>
            <BreadCrumbs prompt="Kundförsäkran" />
            <Header header="Signering krävs" backLink="/" />
            <Container>
                <Show when={order()}>
                    <OrderInfo order={order()} />
                </Show>

                <Show when={is404()}>
                    <StyledVerticalSpace size={0.75} />
                    <p>Kundförsäkran hittades inte.</p>
                </Show>
            </Container>
        </Screen>
    );
};
