import { useParams } from '@solidjs/router';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Screen } from '../../components/layout/screen/screen';
import { ProductCategory } from '../../components/product-categories/product-category';
import { getProductCategoryQuery } from '../../graphql/queries/product-categories';

export const ProductCategoryContainer = () => {
    const { categorySlug } = useParams();
    const { createCachedResource } = useContext(AppContext);
    const [productCategoryData] = createCachedResource(getProductCategoryQuery, { slug: categorySlug });

    return (
        <Screen>
            <ProductCategory productCategoryData={productCategoryData} />
        </Screen>
    );
};
