import { createEffect, onCleanup, Show } from 'solid-js';
import { Transition } from 'solid-transition-group';
import { StyledDarkenLayer, StyledDialogContent, StyledDialogHeader, StyledDialogWrapper } from './dialog.styles';
import { DialogProps } from './dialog.d';
import Button from '../../button/button';
import { isServer } from 'solid-js/web';

export const Dialog = (props: DialogProps) => {
    const handleModalClose = () => {
        document.body.style.overflow = 'auto';

        const layer = document.getElementById('layer');
        layer && layer.remove();
        props.onClose();
    };

    createEffect(() => {
        if (!isServer && props.isOpen) {
            document.body.style.overflow = 'hidden';
        }
    });

    onCleanup(() => {
        if (!isServer) {
            document.body.style.overflow = 'auto';
        }
    });

    return (
        <Transition name="modal">
            <Show when={props.isOpen}>
                <StyledDarkenLayer id="layer">
                    <StyledDialogWrapper>
                        <StyledDialogHeader>
                            <Button
                                onClick={handleModalClose}
                                label={
                                    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.2132 1.79895L29.4975 30.0832"
                                            stroke="#262626"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M29.4975 1.79898L1.2132 30.0833"
                                            stroke="#262626"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                            />
                        </StyledDialogHeader>
                        <StyledDialogContent>{props.children}</StyledDialogContent>
                    </StyledDialogWrapper>
                </StyledDarkenLayer>
            </Show>
        </Transition>
    );
};
