import { For } from 'solid-js';
import { Row } from '../../../isomorphic/components/layout/row/row';
import type { TabsOption, TabsProps } from './tabs-types';
import { StyledTab } from './tabs.styles';

export const Tabs = (props: TabsProps) => (
    <Row alignItems="center" gap={0.5}>
        <For each={props.options}>
            {(option: TabsOption) => (
                <StyledTab
                    disabled={props.disabled || option.disabled}
                    active={props.value === option.value}
                    onClick={() => props.onChange(option.value)}
                >
                    {option.text}
                </StyledTab>
            )}
        </For>
    </Row>
);
