import { DaytimeDegreesEventStrategy } from '../../containers/daytime-degrees/daytime-degrees-types';

const sortedStrategyProducts = (strategy: DaytimeDegreesEventStrategy) => {
    const parents = strategy.products.filter(({ children }) => children?.length);
    if (!parents.length) {
        return strategy.products;
    }

    const childUuids = parents
        .flatMap(({ children = [] }) => children)
        .map(({ uuid }) => uuid)
        .filter((uuid) => uuid);

    return strategy.products.filter(({ uuid }) => !childUuids.includes(uuid));
};

export const strategyTitle = (strategy: DaytimeDegreesEventStrategy) =>
    sortedStrategyProducts(strategy)
        .map(({ name }) => name)
        .join(' + ');
