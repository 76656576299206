import { For, Show } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { Header } from '../layout/header/header';
import { Container } from '../layout/container/container';
import { Link } from '../link/link';
import type { CropsProps } from './crops.d';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import { AdsContainer } from '../../containers/ads/ads-container';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';

export const Crops = (props: CropsProps) => {
    const category = () => props.cropTypeCategoryData()?.cropTypeCategory;
    const cropType = () => props.cropTypeData()?.cropType;
    const prompt = 'Välj gröda';

    const links = () => [
        {
            text: category()?.name,
            url: `/strategier/${category()?.slug}/`,
        },
        {
            text: cropType()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}`,
        },
    ];

    const metaTitle = () => `${cropType()?.name || ''} | ${category()?.name || ''} - Gullvikspraktikan`;

    return (
        <>
            <BreadCrumbs links={links()} prompt={`${prompt}...`} />
            <Title>{metaTitle()}</Title>
            <Header header={cropType()?.name} />
            <Container header={prompt}>
                <Show when={cropType()?.crops?.length} fallback={<SkeletonLoader />}>
                    <For each={cropType()?.crops}>
                        {(crop) => <Link href={`/strategier/${category()?.slug}/${cropType()?.slug}/${crop.slug}`}>{crop.name}</Link>}
                    </For>
                </Show>
                <AdsContainer placement={Placements.CropType} slug={cropType()?.slug} />
            </Container>
        </>
    );
};
