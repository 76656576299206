import { styled } from 'solid-styled-components';
import { ToggleProps } from './toggle';

export const StyledToggleButton = styled.button<ToggleProps>`
    border: none;
    background: none;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        position: absolute;
        left: -25px;
        top: -2px;
        display: inline-block;
        width: 20px;
        border-bottom: 3px solid #607f0b;
    }

    svg {
        cursor: pointer;
        height: 18px;
        width: 18px;
        ${(p) => (p.isOpen ? 'transform: scaleY(-1);' : '')}
    }
`;
