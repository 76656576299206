import { createEffect, onMount, Show } from 'solid-js';
import { SetStoreFunction, Store } from 'solid-js/store';
import { FilterTypeStore } from '../../search';
import { StyledSearchContainer, StyledSearchInput, StyledTag } from './search-bar.styles';

export type SearchBarProps = {
    isLoading: boolean;
    filters: Store<FilterTypeStore>;
    setFilters: SetStoreFunction<FilterTypeStore>;
    removeSelection: (position?: number) => void;
    search: (term?: string) => void;
    secondarySearch: (term?: string) => void;
};

export const SearchBar = (props: SearchBarProps) => {
    let searchBarRef: HTMLInputElement | undefined;
    let secondarySearchBarRef: HTMLInputElement | undefined;

    onMount(() => {
        searchBarRef?.focus();
    });

    createEffect(() => {
        if ((!props.filters.crop && props.filters.treatment) || (!props.filters.treatment && props.filters.crop)) {
            secondarySearchBarRef?.focus();
        }
    });

    const getActiveTag = (position = 1) => {
        if (props.filters.crop?.position === position) {
            return props.filters.crop.name;
        }

        if (props.filters.treatment?.position === position) {
            return props.filters.treatment.name;
        }

        return null;
    };

    const getFilteredSearchPlaceholder = () => (props.filters.crop?.name ? 'Ange ett mål' : 'Ange en gröda');

    return (
        <StyledSearchContainer isLoading={props.isLoading}>
            <Show
                when={!props.filters.crop && !props.filters.treatment}
                fallback={
                    <StyledTag onClick={() => props.removeSelection()}>
                        <span>{getActiveTag()}</span> <span>&#x2715;</span>
                    </StyledTag>
                }
            >
                <StyledSearchInput
                    type="search"
                    ref={searchBarRef}
                    disabled={props.isLoading}
                    value={props.filters.searchTerm}
                    onInput={(e) => props.search(e.currentTarget.value)}
                    placeholder="Sök gröda, mål eller produkt"
                />
            </Show>
            <Show when={(!props.filters.crop && props.filters.treatment) || (!props.filters.treatment && props.filters.crop)}>
                <StyledSearchInput
                    type="search"
                    ref={secondarySearchBarRef}
                    disabled={props.isLoading}
                    value={props.filters.secondarySearchTerm}
                    placeholder={getFilteredSearchPlaceholder()}
                    onClick={() => props.secondarySearch('ALL')}
                    onInput={(e) => props.secondarySearch(e.currentTarget.value)}
                />
            </Show>
            <Show when={props.filters.crop && props.filters.treatment}>
                <StyledTag onClick={() => props.removeSelection(2)}>
                    <span>{getActiveTag(2)}</span> <span>&#x2715;</span>
                </StyledTag>
            </Show>
        </StyledSearchContainer>
    );
};
