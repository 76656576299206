import { Show } from 'solid-js';
import { Route, Routes, useLocation } from '@solidjs/router';
import { Transition } from 'solid-transition-group';
import { Meta, Title } from '@solidjs/meta';
import { GlobalStyles } from './global.styles';
import { StartContainer } from '../containers/start/start-container';
import { CropsContainer } from '../containers/crops/crops-container';
import { PurposesContainer } from '../containers/purposes/purposes-container';
import { TreatmentsContainer } from '../containers/treatments/treatments-container';
import { CropTypesContainer } from '../containers/crop-types/crop-types-container';
import { StrategiesContainer } from '../containers/strategies/strategies-container';
import { ProductCategoriesContainer } from '../containers/product-categories/product-categories-container';
import { ProductCategoryContainer } from '../containers/product-category/product-category-container';
import { DesktopHeader } from '../components/desktop-header/desktop-header';
import { ErrorComponent } from '../components/error';
import { WhereForeArtThou } from '../components/404';
import { ContactUsContainer } from '../containers/contacts/contact-us-container';
import { StyledAppRoot, StyledAppInner } from './app.styles';
import { MixingTableContainer } from '../containers/mixing-table/mixing-table';
import { UpmaContainer } from '../containers/upma/upma-container';
import { CookiePolicy } from '../containers/cookie-policy/cookie-policy-container';
import { FlashContainer } from '../containers/flash/flash-container';
import { ProductsContainer } from '../containers/products/products-container';
import { DaytimeDegreesContainer } from '../containers/daytime-degrees/daytime-degrees-container';
import { CreateDaytimeDegreesContainer } from '../containers/daytime-degrees/create-daytime-degrees-container';
import { OrdersContainer } from '../containers/orders/orders-container';
import { RegCheckContainer } from '../containers/regcheck/regcheck-container';
import { Search } from '../components/search/search';

export const App = () => {
    return (
        <>
            <Title>Gullvikspraktikan</Title>
            {useLocation().pathname === '/' && <Meta name="description" content="Gullvikspraktikan online" />}

            <StyledAppRoot classList={{ home: useLocation().pathname === '/' }}>
                <FlashContainer />
                <Show when={useLocation().pathname !== '/'}>
                    <DesktopHeader />
                </Show>
                <StyledAppInner>
                    <Transition
                        name="slide-fade"
                        onBeforeEnter={(el) => el.classList.add(localStorage?.getItem('direction') || 'forwards')}
                        onAfterEnter={() => localStorage?.removeItem('direction')}
                    >
                        <Routes>
                            <Route path="/" element={<StartContainer />} />

                            <Route path="/kategorier/" element={<ProductCategoriesContainer />} />
                            <Route path="/kategorier/:categorySlug" element={<ProductCategoryContainer />} />
                            <Route path="/kategorier/produkter/:productSlug" element={<ProductsContainer />} />

                            <Route path="/kontakta-oss" element={<ContactUsContainer />} />
                            <Route path="/cookie-policy" element={<CookiePolicy />} />

                            <Route path="/daggrader" element={<DaytimeDegreesContainer />} />
                            <Route path="/daggrader/ny" element={<CreateDaytimeDegreesContainer />} />

                            <Route path="/strategier/:categorySlug" element={<CropTypesContainer />} />
                            <Route path="/strategier/:categorySlug/:cropTypeSlug" element={<CropsContainer />} />
                            <Route path="/strategier/:categorySlug/:cropTypeSlug/:cropSlug" element={<PurposesContainer />} />
                            <Route
                                path="/strategier/:categorySlug/:cropTypeSlug/:cropSlug/:purposeSlug"
                                element={<TreatmentsContainer />}
                            />
                            <Route
                                path="/strategier/:categorySlug/:cropTypeSlug/:cropSlug/:purposeSlug/resultat"
                                element={<StrategiesContainer />}
                            />

                            <Route path="/regkoll" element={<RegCheckContainer />} />
                            <Route path="/blandningstabeller" element={<MixingTableContainer />} />
                            <Route path="/upma" element={<UpmaContainer />} />
                            <Route path="/kundforsakran/:uuid" element={<OrdersContainer />} />
                            <Route path="/error" element={<ErrorComponent />} />
                            <Route path="/*" element={<WhereForeArtThou />} />
                        </Routes>
                    </Transition>
                    <GlobalStyles />
                </StyledAppInner>
                <Search />
            </StyledAppRoot>
        </>
    );
};
