import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Container } from '../../components/layout/container/container';
import { Header } from '../../components/layout/header/header';
import { StyledVerticalSpace } from '../../components/layout/layout.styles';
import { Screen } from '../../components/layout/screen/screen';

export const CookiePolicy = () => (
    <Screen>
        <BreadCrumbs links={[]} prompt="Cookie Policy" />
        <Header header="Cookie policy" />
        <Container>
            <div style={{ padding: '1rem', 'font-size': '1.1rem' }}>
                <h1>Cookies</h1>
                <h2>Vad är cookies?</h2>
                <p>
                    Cookies är små uppsättningar data som sparas i textfiler som lagras på din dator eller motsvarande enhet när webbplatser
                    öppnas i webbläsaren. De används på många ställen för att "komma ihåg" dig och dina val, antingen under ett enskilt
                    besök (med en "sessions-cookie") eller för flera återkommande besök (med hjälp av "permanenta cookies"). De säkerställer
                    att besökare får en konsekvent och effektiv upplevelse, genom att möjliggöra viktiga funktioner. Cookies kan sättas av
                    den webbplats du för tillfället besöker (kallas "förstahands-cookies", first party) eller av utomstående parter, såsom
                    dem som tillhandahåller innehåll, annonsering eller tjänster för statistisk analys på webbplatsen
                    ("tredjeparts-cookies", third party cookies).
                </p>
                <h3>Cookies som sätts av gullvikspraktikan.se</h3>
                <p>
                    Vi använder cookies för en rad olika syften. Vissa cookies är nödvändiga av tekniska skäl, t.ex. för att möjliggöra en
                    personlig upplevelse och andra gör det möjligt att visa annonser från utvalda annonsnätverk. Vissa av dessa cookies kan
                    sättas när en sida laddas eller när en besökare utför en viss åtgärd (t.ex. klicka på en knapp).
                </p>
                <p>
                    Nedan beskrivs de olika kategorierna av cookie-filer som gullvikspraktikan.se sätter, med beskrivningar av specifika
                    exempel i de efterföljande tabellerna. Detta inkluderar deras namn och deras syfte. Vissa cookie-filer sätts enbart för
                    inloggade användare medan andra sätts för alla, och även detta anges. Om en cookie endast avser vissa subdomäner
                    inkluderas den under motsvarande rubrik.
                </p>

                <StyledVerticalSpace />

                <h3>Obbligatoriska</h3>
                <p>
                    <strong>Absolut nödvändiga:</strong> Detta är de cookie-filer som är nödvändiga för att gullvikspraktikan.se ska kunna
                    utföra grundläggande funktioner.
                </p>
                <p>
                    <strong>Funktionalitet:</strong> Dessa cookie-filer används för att spara alternativ som användare har valt.
                </p>

                <h3>Statistik</h3>
                <p>
                    <strong>Prestanda:</strong> Cookies för prestandamätning samlar information om hur användare interagerar med webbplatser
                    som körs av gullvikspraktikan.se, inklusive vilka sidor de besöker oftast och vissa andra uppgifter för analys. Dessa
                    uppgifter används enbart för att förbättra webbplatsens funktion.
                </p>
                <p>
                    <strong>Spårning:</strong> Dessa sätts av betrodda utomstående nät (t.ex. Google Analytics) för att spåra sådana saker
                    som antalet unika besökare och antalet sidvisningar för att hjälpa till att förbättra användarupplevelsen.
                </p>
            </div>
        </Container>
    </Screen>
);
