import { StyledToggleButton } from './toggle.styles';
import { colors } from '../../../style/style-variables';

export type ToggleProps = {
    isOpen: boolean;
    onClick?: () => unknown;
};

export const Toggle = (props: ToggleProps) => (
    <StyledToggleButton type="button" isOpen={props.isOpen} onClick={props?.onClick}>
        <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={colors.red}
                d="M6.30742 4.43048L10.4708 0.311734C10.891 -0.103911 11.5722 -0.103911 11.9923 0.311734C12.4125 0.72738 12.4125 1.40128 11.9923 1.81692L7.06817 6.68827C6.64801 7.10392 5.9668 7.10391 5.54665 6.68826L0.622572 1.81691C0.202422 1.40126 0.20243 0.727367 0.622588 0.311726C1.04275 -0.103916 1.72395 -0.103908 2.1441 0.311742L6.30742 4.43048Z"
            />
        </svg>
    </StyledToggleButton>
);
