import { gql } from 'graphql-request';

export const getCropTypeCategoriesQuery = gql`
    query cropTypeCategories($searchTerm: String, $limit: Int, $offset: Int) {
        cropTypeCategories: getCategories(searchTerm: $searchTerm, limit: $limit, offset: $offset) {
            count
            rows {
                slug
                name
                cropTypes {
                    slug
                    name
                }
            }
        }
    }
`;

export const getCropTypeCategoryQuery = gql`
    query cropTypeCategory($slug: String) {
        cropTypeCategory: getCategory(slug: $slug) {
            slug
            name
            cropTypes {
                slug
                name
            }
        }
    }
`;

export const getCropTypesQuery = gql`
    query cropTypes($searchTerm: String, $limit: Int, $offset: Int) {
        cropTypes: getCropTypes(searchTerm: $searchTerm, limit: $limit, offset: $offset) {
            count
            rows {
                slug
                name
            }
        }
    }
`;

export const getCropTypeQuery = gql`
    query cropType($slug: String) {
        cropType: getCropType(slug: $slug) {
            slug
            name
            crops {
                slug
                name
            }
        }
    }
`;

export const getCropsQuery = gql`
    query crops($searchTerm: String, $limit: Int, $offset: Int) {
        crops: getCrops(searchTerm: $searchTerm, limit: $limit, offset: $offset) {
            count
            rows {
                slug
                name
                cropType {
                    slug
                    name
                }
            }
        }
    }
`;

export const getCropQuery = gql`
    query crop($slug: String) {
        crop: getCrop(slug: $slug) {
            slug
            name
            cropType {
                slug
                name
            }
        }
    }
`;
