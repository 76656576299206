import { Accessor, createSignal } from 'solid-js';
import {
    StyledRegCheckSearchContainer,
    StyledRegCheckSearchInput,
    StyledRegCheckSearchContainerWrapper,
} from './regcheck-search-field.styles';
import { debounce } from '../../tools/debounce';

type Props = {
    searchQuery: Accessor<string>;
    sendSearchQuery: (query: string) => void;
};



export const RegCheckSearchField = (props: Props) => {
    const [query, setQuery] = createSignal('');

    const debouncedSendSearchQuery = debounce(props.sendSearchQuery, 300);

    const handleSearch = (e: any) => {
        const query = e.target.value;
        setQuery(query);
        
        debouncedSendSearchQuery(query);
    }

    return (
        <StyledRegCheckSearchContainerWrapper>
            <StyledRegCheckSearchContainer>
                <StyledRegCheckSearchInput
                    type="search"
                    value={query()}
                    onInput={handleSearch}
                    placeholder="Sök..."
                />
            </StyledRegCheckSearchContainer>
        </StyledRegCheckSearchContainerWrapper>
    );
};
