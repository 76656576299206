import { useParams } from '@solidjs/router';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Crops } from '../../components/crops/crops';
import { Screen } from '../../components/layout/screen/screen';
import { getCropTypeCategoryQuery, getCropTypeQuery } from '../../graphql/queries/crops';

export const CropsContainer = () => {
    const { categorySlug, cropTypeSlug } = useParams();
    const { createCachedResource } = useContext(AppContext);

    const [cropTypeCategoryData] = createCachedResource(getCropTypeCategoryQuery, { slug: categorySlug });
    const [cropTypeData] = createCachedResource(getCropTypeQuery, { slug: cropTypeSlug });

    return (
        <Screen>
            <Crops cropTypeCategoryData={cropTypeCategoryData} cropTypeData={cropTypeData} />
        </Screen>
    );
};
