import { createSignal, Show } from 'solid-js';
import { isAfter, isBefore } from 'date-fns';
import { Link } from '@solidjs/router';
import { ForbiddenIcon } from '../../icons/forbidden';
import { PassingTimeIcon } from '../../icons/passing-time';
import { CheckmarkIcon } from '../../icons/checkmark';
import { ChevronDownIcon } from '../../icons/chevron';
import {
    StyledRegCheckSearchResultsItem,
    StyledRegCheckSearchResultsItemTop,
    StyledRegistrationNumberCheckItemStatusDot,
    StyledRegCheckSearchResultsItemLeft,
    StyledRegCheckSearchResultsItemName,
    StyledRegCheckSearchResultsItemRight,
    StyledRegistrationNumberCheckItemStatusDotContainer,
    StyledRegCheckSearchResultsItemBottom,
    StyledRegCheckSearchResultsItemBottomRow,
    StyledRegCheckSearchResultsItemBottomAccordion,
} from './regcheck-search-results-item.styles';
import { MatchingProduct } from '../../../containers/regcheck/regcheck-container';

const getProductStatus = (item: RegistrationNumberCheckItemType) => {
    const today = new Date();

    if (item.approvalCeasedDate && isAfter(new Date(item.approvalCeasedDate), today)) {
        return 'outgoing';
    }

    if (item.usageForbiddenDate) {
        if (isBefore(new Date(item.usageForbiddenDate), today)) {
            return 'expired';
        }

        return 'outgoing';
    }

    if (item.approvalCeasedDate && isBefore(new Date(item.approvalCeasedDate), today)) {
        return 'expired';
    }

    return 'approved';
};

const getStatusRelatedElements = (status: 'approved' | 'outgoing' | 'expired') => {
    switch (status) {
        case 'approved': {
            return {
                status,
                icon: <CheckmarkIcon />,
                statusText: 'Godkänd produkt',
            };
        }
        case 'outgoing':
            return {
                status,
                icon: <PassingTimeIcon />,
                statusText: 'Utgående produkt',
            };
    }

    return {
        status,
        icon: <ForbiddenIcon />,
        statusText: 'Förbjuden att användas',
    };
};

export type RegistrationNumberCheckItemType = {
    name: string;
    id: string;
    regNumber: string;
    approvalCeasedDate: string;
    usageForbiddenDate: string;
    matches?: {
        indices: [[number, number]];
        value: string;
        key: string;
    }[];
};

type RegCheckSearchResultsItemProps = {
    item: RegistrationNumberCheckItemType;
    matchedProducts: MatchingProduct[];
};

export const RegCheckSearchResultsItem = (props: RegCheckSearchResultsItemProps) => {
    const [bottomPanelOpen, setBottomPanelOpen] = createSignal(false);
    const status = getProductStatus(props.item);
    const statusRelatedElements = getStatusRelatedElements(status);

    const productLink = () => {
        if (status === 'expired') {
            return null;
        }

        return props.matchedProducts?.find(({ registrationNumber }) => registrationNumber === props.item.regNumber) || null;
    };

    const highligtMatch = (field: 'name' | 'regNumber' = 'name') => {
        let markedValue = props.item[field];

        props.item.matches
            ?.filter(({ key }) => key === field)
            ?.forEach(({ indices }) => {
                indices.forEach(([startIndex, endIndex]) => {
                    if (startIndex !== endIndex && !markedValue.includes('<mark>')) {
                        markedValue =
                            markedValue.substring(0, startIndex) +
                            `<mark>${markedValue.substring(startIndex, endIndex + 1)}</mark>` +
                            markedValue.substring(endIndex + 1);
                    }
                });
            });

        return markedValue;
    };

    return (
        <StyledRegCheckSearchResultsItem onClick={() => setBottomPanelOpen(!bottomPanelOpen())}>
            <StyledRegCheckSearchResultsItemTop>
                <StyledRegCheckSearchResultsItemLeft>
                    <StyledRegistrationNumberCheckItemStatusDotContainer>
                        <StyledRegistrationNumberCheckItemStatusDot status={statusRelatedElements.status}>
                            {statusRelatedElements.icon}
                        </StyledRegistrationNumberCheckItemStatusDot>
                    </StyledRegistrationNumberCheckItemStatusDotContainer>
                    <div>
                        <StyledRegCheckSearchResultsItemName innerHTML={highligtMatch()} />
                        <div>
                            regnr: <span innerHTML={highligtMatch('regNumber')} />
                        </div>
                    </div>
                </StyledRegCheckSearchResultsItemLeft>
                <StyledRegCheckSearchResultsItemRight open={bottomPanelOpen()}>
                    <ChevronDownIcon />
                </StyledRegCheckSearchResultsItemRight>
            </StyledRegCheckSearchResultsItemTop>
            <StyledRegCheckSearchResultsItemBottomAccordion open={bottomPanelOpen()}>
                <StyledRegCheckSearchResultsItemBottom>
                    <StyledRegCheckSearchResultsItemBottomRow>
                        <strong>Status:</strong>
                        <div>{statusRelatedElements.statusText}</div>
                    </StyledRegCheckSearchResultsItemBottomRow>
                    <Show when={props.item.approvalCeasedDate}>
                        <StyledRegCheckSearchResultsItemBottomRow>
                            <strong>
                                {isBefore(new Date(props.item.approvalCeasedDate), new Date())
                                    ? 'Godkännande upphörde'
                                    : 'Godkännande upphör'}
                                :
                            </strong>
                            <div>{props.item.approvalCeasedDate}</div>
                        </StyledRegCheckSearchResultsItemBottomRow>
                    </Show>
                    <Show when={props.item.usageForbiddenDate}>
                        <StyledRegCheckSearchResultsItemBottomRow>
                            <strong>Sista användningsdag:</strong>
                            <div>{props.item.usageForbiddenDate}</div>
                        </StyledRegCheckSearchResultsItemBottomRow>
                    </Show>
                    <Show when={productLink()}>
                        {(link) => (
                            <StyledRegCheckSearchResultsItemBottomRow center>
                                <Link href={`/kategorier/produkter/${link()?.slug}`}>Visa produkten</Link>
                            </StyledRegCheckSearchResultsItemBottomRow>
                        )}
                    </Show>
                </StyledRegCheckSearchResultsItemBottom>
            </StyledRegCheckSearchResultsItemBottomAccordion>
        </StyledRegCheckSearchResultsItem>
    );
};
