import { gql } from 'graphql-request';

export const getUpmaListQuery = gql`
    query upmaList($activeOnly: Boolean, $productSlugs: [String], $searchTerm: String, $limit: Int, $offset: Int) {
        upmaList: getUpmaList(
            activeOnly: $activeOnly
            productSlugs: $productSlugs
            searchTerm: $searchTerm
            limit: $limit
            offset: $offset
        ) {
            count
            rows {
                areaOfUse
                decisionText
                documentId
                decisionId
                approvedDate
                approvedUntilDate
                approvalCeasedDate
                usageForbiddenDate
                registrationNumber {
                    isApproved
                    registrationNumber
                    product {
                        slug
                        name
                    }
                }
            }
        }
    }
`;
