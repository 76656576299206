import { styled } from "solid-styled-components";
import { colors } from "../../style/style-variables";

export const StyledDesktopCategoriesMenu = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1;

    @media screen and (max-width: 769px) {
        display: none;
    }
`;

export const StyledDesktopCategoriesMenuLink = styled.div`
    a {
        display: block;
        color: #5c5c5c !important;
        background-color: #e6e6e6;
        border-radius: 8px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        padding: 1.5rem 0;
        text-align: center;
        width: 280px;
    }

    a.active {
        color: white !important;
        background-color: ${colors.red};
    }
`;
