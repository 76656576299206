import { styled } from 'solid-styled-components';

export const StyledRegCheckSearchResultsHeadline = styled.h4`
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    margin: 0 2rem 1rem 2rem;
`;

export const StyledRegCheckSearchResultsText = styled.p`
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin: 0 2rem;
`;

export const StyledRegCheckSearchPromptImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 172px;
`;
