import { createEffect, For, Show, useContext } from 'solid-js';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { createStore } from 'solid-js/store';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Ad as AdComponent } from '../../components/ads/ad';
import { getRandomAdsQuery } from '../../graphql/queries/ads';
import type { Ad } from '../../components/ads/ad.d';
import { StyledAdsContainer } from '../../components/ads/ad.styles';

type ComponentProps = {
    placement: Placements;
    slug?: string | null;
};

export const AdsContainer = (props: ComponentProps) => {
    const { graphQlClient } = useContext(AppContext);
    const [ads, setAds] = createStore<Ad[]>([]);

    createEffect(async () => {
        const month = new Date().getMonth();
        const response = await graphQlClient(getRandomAdsQuery, { ...props, month });
        const items = response?.ads?.rows || [];

        if (JSON.stringify(items) !== JSON.stringify(ads)) {
            setAds(response?.ads?.rows || []);
        }
    });

    return (
        <Show when={ads?.length}>
            <StyledAdsContainer>
                <For each={ads}>{(ad: Ad) => <AdComponent ad={ad} />}</For>
            </StyledAdsContainer>
        </Show>
    );
};
