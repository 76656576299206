import { For } from 'solid-js';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { Title } from '@solidjs/meta';
import { AdsContainer } from '../../containers/ads/ads-container';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import { Container } from '../layout/container/container';
import { Header } from '../layout/header/header';
import { Link } from '../link/link';
import { ProductCategoryProps } from './product-categories.d';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';

export const ProductCategory = (props: ProductCategoryProps) => {
    const productCategory = () => props.productCategoryData()?.productCategory;

    const links = () => [
        {
            text: 'Produkter',
            url: '/kategorier',
        },
        {
            text: productCategory()?.name || '',
            url: `/kategorier/${productCategory()?.slug}/`,
        },
    ];

    return (
        <>
            <BreadCrumbs links={links()} prompt="Välj produkt..." />
            <Title>{`${productCategory()?.name || ''} | Produkter - Gullvikspraktikan`}</Title>
            <Header header={productCategory()?.name} />
            <Container header="Välj produkt">
                <For each={productCategory()?.products} fallback={<SkeletonLoader />}>
                    {(product) => <Link href={`/kategorier/produkter/${product.slug}`}>{product.name}</Link>}
                </For>
                <AdsContainer placement={Placements.ProductCategory} slug={productCategory()?.slug} />
            </Container>
        </>
    );
};
