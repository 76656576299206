import { gql } from 'graphql-request';

export const getDaytimeDegreesQuery = gql`
    query daytimeDegrees($cropSlug: String) {
        daytimeDegrees: getDaytimeDegreesByCrop(cropSlug: $cropSlug) {
            crop {
                name
                slug
            }
            events {
                tempSpanLow
                tempSpanHigh
                countFromYearStart
                baseTemp
                text
                eventName
                showStrategies
                treatments {
                    name
                    slug
                }
                products {
                    name
                    slug
                }
            }
        }
    }
`;

export const getDaytimeDegreesCropsQuery = gql`
    query daytimeDegreesCrops($limit: Int, $offset: Int, $cropSlug: String) {
        daytimeDegreesCrops: getDaytimeDegreesList(limit: $limit, offset: $offset, cropSlug: $cropSlug) {
            count
            rows {
                crop {
                    name
                    slug
                }
                dateTitle
            }
        }
    }
`;

export const getDaytimeDegreesWeatherData = gql`
    query getWeatherData($lat: Float, $lng: Float, $startDate: String) {
        weatherData: getWeatherData(lat: $lat, lng: $lng, startDate: $startDate) {
            error
            data {
                date
                average
            }
        }
    }
`;
