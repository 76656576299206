import ax400 from '../../../client/assets/images/ax_400.jpg';
import gran400 from '../../../client/assets/images/gran_400.jpg';
import jordgubbar400 from '../../../client/assets/images/jordgubbar_400.jpg';
import raps400 from '../../../client/assets/images/raps_400.jpg';


const imageUrls = [
    ax400,
    gran400,
    jordgubbar400,
    raps400
]

const bottomBackgroundColors: BottomBackgroundColor[] = [
    'green',
    'red',
    'red',
    'blue'
];

export const getImageUrlByIndex = (index: number) => {
    return imageUrls[index];
};

export const getBottomBackgroundColor = (index: number): BottomBackgroundColor => {
    return bottomBackgroundColors[index];
};

export type BottomBackgroundColor = ('red' | 'blue' | 'green');