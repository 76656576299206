import { Title } from '@solidjs/meta';
import { useParams } from '@solidjs/router';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Container } from '../../components/layout/container/container';
import { Header } from '../../components/layout/header/header';
import { Screen } from '../../components/layout/screen/screen';
import { Product } from '../../components/products/product';
import { getProductQuery } from '../../graphql/queries/products';

export const ProductsContainer = () => {
    const params = useParams();
    const { createCachedResource } = useContext(AppContext);

    const [productData] = createCachedResource(getProductQuery, () => ({ slug: params.productSlug }));
    const product = () => productData()?.product;

    const links = () => {
        if (!product()) {
            return [];
        }

        const productCategory = product().categories[0];

        if (!productCategory) {
            return [
                {
                    text: 'Produkter',
                    url: '/kategorier',
                },
            ];
        }

        return [
            {
                text: 'Produkter',
                url: '/kategorier',
            },
            {
                text: productCategory.name,
                url: `/kategorier/${productCategory.slug}`,
            },
        ];
    };

    const header = () => {
        if (!product()) {
            return '';
        }

        const productCategory = product().categories[0];

        return productCategory?.name || '';
    };

    return (
        <Screen>
            <BreadCrumbs links={links()} prompt="Produkter" />
            <Title>{`${product()?.name || ''} | Produkter - Gullvikspraktikan`}</Title>
            <Header header={header()} />
            <Container header={product()?.name || ''}>
                <Product productData={productData} />
            </Container>
        </Screen>
    );
};
