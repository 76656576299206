import { Component, Show, mergeProps } from 'solid-js';
import { InputProps } from './input.d';
import { StyledInputComponent, StyledInput, StyledInputWrapper, InputLabel, StyledTextarea } from './input.styles';

export const Input: Component<InputProps> = (componentProps: InputProps) => {
    const props = mergeProps({ type: 'text', placeholder: '' }, componentProps);

    const onInput = (e: Event) => {
        const el = e.currentTarget as HTMLInputElement;

        if (props.onChange) {
            props.onChange(el.value);
        }
        if (props.onInput) {
            props.onInput(el.value);
        }
    };

    const onBlur = (e: Event) => {
        const el = e.currentTarget as HTMLInputElement;

        if (props.onBlur) {
            props.onBlur(el.value);
        }
    };

    return (
        <StyledInputWrapper>
            <Show when={props.label}>
                <InputLabel for="input">
                    <span>{props.label}</span>
                </InputLabel>
            </Show>
            <StyledInputComponent backgroundColor={props.backgroundColor}>
                <Show
                    when={props.type === 'textarea'}
                    fallback={
                        <StyledInput
                            value={props.value !== null ? props.value : ''}
                            onInput={onInput}
                            onBlur={onBlur}
                            type={props.type}
                            placeholder={props.placeholder || ''}
                            disabled={props.disabled}
                            backgroundColor={props.backgroundColor}
                            max={props.max}
                        />
                    }
                >
                    <StyledTextarea
                        value={props.value !== null ? props.value : ''}
                        onInput={onInput}
                        onBlur={onBlur}
                        placeholder={props.placeholder || ''}
                        rows={props.rows}
                        cols={props.cols}
                        disabled={props.disabled}
                    />
                </Show>
            </StyledInputComponent>
        </StyledInputWrapper>
    );
};
