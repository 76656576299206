import { createStore } from 'solid-js/store';
import { Accessor, createContext, createEffect, createResource, createSignal, ResourceReturn, sharedConfig } from 'solid-js';
import { isServer } from 'solid-js/web';
import getHashKey from '../tools/get-hash-key';

type AppContextProviderProps = {
    client: (query: string, variables: any) => any;
    cache: any;
    children?: any;
    imagesServiceUrl: string;
    googleMapsApiKey: string;
};

type ContextType = {
    createCachedResource: (graphQlQuery: string, variables?: any, useCache?: boolean) => ResourceReturn<any>;
    graphQlClient: (graphQlQuery: string, variables: any, useCache?: boolean) => Promise<any>;
    imagesServiceUrl: string;
    googleMapsApiKey: string;
    isSearchOpen: Accessor<boolean>;
    setIsSearchOpen: (arg: boolean) => void;
};

export const AppContext = createContext<ContextType>({
    createCachedResource: () => createResource(() => true),
    graphQlClient: () => new Promise((resolve) => resolve(1)),
    imagesServiceUrl: '',
    googleMapsApiKey: '',
    isSearchOpen: () => false,
    setIsSearchOpen: (_: boolean) => _,
});

export function AppContextProvider(props: AppContextProviderProps) {
    const [cache, setCache] = createStore(props.cache);
    const [isSearchOpen, setIsSearchOpen] = createSignal(false);

    createEffect(() => {
        document.body.style.overflow = isSearchOpen() ? 'hidden' : 'visible';
        document.body.style.height = isSearchOpen() ? '100%' : 'auto';
    });

    function getInitialValue(key: string, useCache = true): string {
        const root = '0-0-0-0-0-0-0';
        if (!isServer && sharedConfig.context?.id !== root + '-') {
            useCache = true;
        }
        return useCache ? cache[key] : '';
    }

    const graphQlClient = async (graphQlQuery = '', variables = {}, useCache = true): Promise<any> => {
        const key = getHashKey(graphQlQuery, variables);

        if (useCache && cache[key]) {
            return Promise.resolve(cache[key]);
        }

        const response = await props.client(graphQlQuery, variables);

        if (useCache) {
            setCache({ [key]: response });
        }

        return response;
    };

    const createCachedResource = (graphQlQuery = '', variables: any = {}, useCache = true) => {
        const key = getHashKey(graphQlQuery, typeof variables === 'function' ? variables() : variables);
        if (isServer) {
            useCache = true;
        }

        async function fetcher(_: any, { refetching }: { refetching?: unknown }): Promise<any> {
            if (refetching) {
                useCache = false;
            }

            if (typeof variables === 'function') {
                return graphQlClient(graphQlQuery, variables(), useCache);
            }
            return graphQlClient(graphQlQuery, variables, useCache);
        }

        if (typeof variables === 'function') {
            return createResource(variables, fetcher, { initialValue: getInitialValue(key, useCache) });
        }

        return createResource(fetcher, { initialValue: getInitialValue(key, useCache) });
    };

    return (
        <AppContext.Provider
            value={{
                imagesServiceUrl: props.imagesServiceUrl,
                createCachedResource,
                graphQlClient,
                googleMapsApiKey: props.googleMapsApiKey,
                isSearchOpen,
                setIsSearchOpen,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}
