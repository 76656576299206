import { styled } from 'solid-styled-components';

export const StyledPagination = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    list-style: none;
    margin: 1rem auto 2rem;
    padding: 0;
    line-height: 1;
`;

export const StyledPaginationItem = styled.li`
    padding: 0;
    margin: 0;

    a {
        display: flex;
        min-width: 32px;
        height: 32px;
        line-height: 1;
        align-items: center;
        justify-content: space-around;
        list-style: none;
        background-color: rgb(255 255 255);
        border: 1px solid rgb(0 0 0 / 20%);
        border-radius: 3px;
        outline: 0;
        color: rgb(0 0 0 / 85%);
        cursor: pointer;

        &.active {
            border-color: #607f0b;
            color: #607f0b;
        }

        &.icon-wrapper {
            line-height: 0;

            &.active {
                border-color: rgb(0 0 0 / 20%);
                color: rgb(0 0 0 / 85%);
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &:hover {
            letter-spacing: -0.5px;
            transition: all 0.2s;
        }
    }

    .dots {
        color: grey;
        border: none;
    }
`;
