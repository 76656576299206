import { styled } from "solid-styled-components";
import { colors } from "../../style/style-variables";

export const StyledTab = styled.button<StyledTabProps>`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 6px;

    ${(p) =>
        p.active
            ? `
        background: ${colors.red};
        color: white;

    `
            : `
        background: #00000008;
        color: ${colors.red};

        &:disabled {
            opacity: .2;
            cursor: default;
        }
    `}
`;

export type StyledTabProps = {
    active?: boolean;
};
