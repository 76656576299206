import { For } from 'solid-js';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { AdsContainer } from '../../containers/ads/ads-container';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import { Container } from '../layout/container/container';
import { Header } from '../layout/header/header';
import { Link } from '../link/link';
import { ProductCategoriesProps } from './product-categories.d';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';

export const ProductCategories = (props: ProductCategoriesProps) => {
    const productCategories = () => props.productCategoriesData()?.productCategories;
    const prompt = 'Välj produktkategori';

    const links = [
        {
            text: 'Produkter',
            url: '/kategorier',
        },
    ];

    return (
        <>
            <BreadCrumbs links={links} prompt={`${prompt}...`} />
            <Header header="Produkter" backLink="/" />
            <Container header={prompt}>
                <For each={productCategories()?.rows} fallback={<SkeletonLoader />}>
                    {(term) => <Link href={`/kategorier/${term.slug}`}>{term.name}</Link>}
                </For>
                <AdsContainer placement={Placements.ProductCategories} />
            </Container>
        </>
    );
};
