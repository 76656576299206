import { gql } from 'graphql-request';

export const getRandomAdsQuery = gql`
    query ads($placement: String!, $month: Int!, $slug: String) {
        ads: random(placement: $placement, month: $month, slug: $slug) {
            rows {
                url
                imageSrc
            }
        }
    }
`;
