import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';
import { StyleProps } from './button.d';

export const StyledButton = styled.button<StyleProps>`
    background: ${(p) => (p.variant === 'primary' ? colors.red : 'none')};
    color: ${(p) => (p.variant === 'primary' ? '#fff' : '#607f0b')};
    border-radius: ${(p) => (p.size === 'small' ? '30px' : '60px')};
    padding: ${(p) => (p.size === 'small' ? '0.25rem 0.75rem' : '0.75rem 1.5rem')};
    border: ${(p) => (p.variant === 'primary' ? 0 : '1px solid #607f0b')};
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
    font-size: 16px;
`;
