import { styled } from 'solid-styled-components';

export const StyledAdditionalConditions = styled.div`
    margin-top: 2rem;
`;

export const StyledAdditionalConditionsItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const StyledAdditionalConditionsItem = styled.div`
    background: transparent;
    box-shadow: 0px 1px 1px 0px #00000033;
    border-radius: 6px;

    div {
        background: white;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #e4e4e4;
    }

    div:first-child {
        border-radius: 6px 6px 0 0;
    }

    div:last-child {
        border-radius: 0 0 6px 6px;
        border-bottom: none;
    }

    div:nth-child(even) {
        background-color: #f9f9f9;
    }
`;

export const StyledAdditionalConditionsItemHeader = styled.div`
    font-weight: 700;
    padding: 12px 16px 12px 16px;
`;

export const StyledAdditionalConditionsItemRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 12px 16px 12px 16px;

    p {
        white-space: pre-wrap;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
