import { styled } from "solid-styled-components";
import { colors } from "../../style/style-variables";

export const StyledDisclaimer = styled.div`
    padding-top: 2rem;
    color: ${colors.red};
    text-align: center;
    font-style: italic;
    font-size: 12px;
    max-width: 560  px;
    margin: 0 auto;

    @media (max-width: 480px) {
        padding: 1rem;
    }
`;