import { styled } from 'solid-styled-components';

export const StyledFlashContainer = styled.div`
    font-size: 1.2rem;

    .splide__slide {
        text-align: center;
        padding: 1rem 3rem;
    }
`;

export const StyledFlashText = styled.div`
    padding: 0.25rem 0;
    text-align: center;

    p {
        margin: 0;
    }
`;
