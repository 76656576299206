import { styled } from 'solid-styled-components';

export const StyledHorizontalSpace = styled.div<HorizontalSpaceProps>`
    width: ${p => p.size ? `${p.size}rem` : '2rem'};
`;
export type HorizontalSpaceProps = {
    size?: number;
}

export const StyledVerticalSpace = styled.div<VerticalSpaceProps>`
    height: ${p => p.size ? `${p.size}rem` : '2rem'};
`;
export type VerticalSpaceProps = {
    size?: number;
}

export const StyledFormBackground = styled.div`
    flex: 1;
    height: 100%;
    background-color: #f3f3f3;
    padding: 2rem;
    margin-top: 5px;
`;

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
