import type { Strategy } from '../strategies/strategies';
import type { Crop } from '../crops/crops';
import type { Treatment } from '../treatments/treatments';

type ApiResponse = {
    name: string;
    slug: string;
};

export const getRelatedModelsCount = (strategies: Strategy[]) => {
    const totalCrops = new Set<string>();
    const totalProducts = new Set<string>();
    const totalTreatments = new Set<string>();

    strategies.forEach(({ crop, treatments, products }) => {
        totalCrops.add(crop.slug);
        products.forEach(({ slug }) => totalProducts.add(slug));
        treatments.forEach(({ slug }) => totalTreatments.add(slug));
    });

    return {
        crops: totalCrops.size,
        products: totalProducts.size,
        treatments: totalTreatments.size,
    };
};

export const getAllProductsForStrategies = (strategies: Strategy[]) => {
    const filteredProducts: { name: string; slug: string }[] = [];

    strategies.forEach(({ products }) =>
        products.forEach(({ name, slug }) => {
            if (!filteredProducts.find((product) => product.slug === slug)) {
                filteredProducts.push({ name, slug });
            }
        })
    );

    return filteredProducts.sort((a, b) => a.name.localeCompare(b.name, 'sv'));
};

export const getFilteredStrategies = (strategies: Strategy[], crop: Crop, treatment: Treatment) => {
    return strategies.filter((strategy) => {
        if (crop.slug !== strategy.crop.slug) {
            return false;
        }

        return !!strategy.treatments.find((t) => t.slug === treatment.slug);
    });
};

export const filterRelatedModels = (strategies: Strategy[], searchTerm = '', filterType: 'crop' | 'treatment' = 'crop') => {
    const regEx = new RegExp(`.?${searchTerm}.?`, 'ig');

    const filteredProducts: ApiResponse[] = [];
    const filteredTreatments: ApiResponse[] = [];
    const filteredCrops: ApiResponse[] = [];

    strategies.forEach(({ crop, treatments, products }) => {
        // Always check products for a match
        products
            .filter((product) => product.name.match(regEx))
            .forEach(({ name, slug }) => {
                if (!filteredProducts.find((product) => product.slug === slug)) {
                    filteredProducts.push({ name, slug });
                }
            });

        // If it's a crop selected just check treatments for a match
        if (filterType === 'crop') {
            treatments
                .filter((treatment) => treatment.name.match(regEx))
                .forEach(({ name, slug }) => {
                    if (!filteredTreatments.find((treatment) => treatment.slug === slug)) {
                        filteredTreatments.push({ name, slug });
                    }
                });

            return;
        }

        // Implicitly there is a treatment selected, so check if the crop matches
        if (crop.name.match(regEx) && !filteredCrops.find((newCrop) => newCrop.slug === crop.slug)) {
            filteredCrops.push({
                name: crop.name,
                slug: crop.slug,
            });
        }
    });

    return {
        filteredProducts: filteredProducts.sort((a, b) => a.name.localeCompare(b.name, 'sv')),
        filteredTreatments: filteredTreatments.sort((a, b) => a.name.localeCompare(b.name, 'sv')),
        filteredCrops: filteredCrops.sort((a, b) => a.name.localeCompare(b.name, 'sv')),
    };
};
