import { useNavigate } from '@solidjs/router';
import Button from './button/button';
import { Container } from './layout/container/container';
import { Header } from './layout/header/header';
import { Row } from './layout/row/row';
import { Screen } from './layout/screen/screen';

export const WhereForeArtThou = () => {
    const navigate = useNavigate();

    return (
        <Screen>
            <Header header="Fel" />
            <Container>
                <Row justifyContent="center">
                    <p>Det uppstod ett fel!</p>
                </Row>
                <Row justifyContent="center">
                    <Button variant="secondary" onClick={() => navigate('/')} label="Tillbaka" class="back-link" />
                </Row>
            </Container>
        </Screen>
    );
};
