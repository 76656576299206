import { Component, mergeProps } from 'solid-js';

import { IconButtonProps } from './icon-button-types';
import { StyledIconButton } from './icon-button.styles';

const IconButton: Component<IconButtonProps> = (componentProps: IconButtonProps) => {
    const props = mergeProps({ variant: 'primary', size: 'medium' }, componentProps);

    return (
        <StyledIconButton
            size={props.size}
            variant={props.variant}
            id={props.id}
            onClick={() => props.onClick()}
            disabled={props.disabled}
            color={props.color ? props.color : 'red'}
        >
            {props.icon}
        </StyledIconButton>
    );
};

export default IconButton;
