import { format } from 'date-fns';
import sv from 'date-fns/locale/sv/index.js';
import { For, Show } from 'solid-js';
import { Link } from '@solidjs/router';
import { DaytimeDegreesEventRelatedModel, DaytimeDegreesItemEventType } from '../../containers/daytime-degrees/daytime-degrees-types';
import {
    StyledDaytimeDegreesCard,
    StyledItemCardHeader,
    StyledNoteRow,
    StyledIconWrapper,
    StyledCardTopContent,
    StyledRemoveItemLink,
    StyledErrorContent,
    StyledLatLng,
} from './daytime-degrees-item.styles';
import { NoteIcon } from '../icons/note';
import { Crop } from '../crops/crops.d';
import { Treatment } from '../treatments/treatments.d';
import { CropTypeCategory } from '../start/start-types';
import { DaytimeDegreesItemEvent } from './daytime-degrees-item-event';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';

type DaytimeDegreesItemProps = {
    note: string;
    isLoading: boolean;
    sowingDate: string;
    error: string | null;
    hiddenTreatments: string[];
    dateTitle: string;
    latLng: {
        lat: string | number;
        lng: string | number;
    } | null;
    crop: Crop;
    treatments: Treatment[];
    categories: CropTypeCategory[];
    events: DaytimeDegreesItemEventType[];
    removeItem: () => void;
    hideEvent: (treatments: DaytimeDegreesEventRelatedModel[]) => void;
};

export const DaytimeDegreesItem = (props: DaytimeDegreesItemProps) => {
    const getFormattedDate = () => {
        const date = new Date(props.sowingDate);
        return format(date, 'd MMMM, yyyy', { locale: sv });
    };

    return (
        <StyledDaytimeDegreesCard>
            <StyledCardTopContent>
                <StyledItemCardHeader>{props.crop?.name}</StyledItemCardHeader>
                {props.dateTitle}: <strong>{getFormattedDate()}</strong>
                <Show when={props.latLng}>
                    <StyledLatLng>
                        <strong>Koordinator:</strong>{' '}
                        <Link target="_blank" href={`https://maps.google.com/?q=${props.latLng?.lat},${props.latLng?.lng}`}>
                            {props.latLng?.lat}, {props.latLng?.lng}
                        </Link>
                    </StyledLatLng>
                </Show>
                <Show when={props.note}>
                    <StyledNoteRow>
                        <StyledIconWrapper>
                            <NoteIcon />
                        </StyledIconWrapper>
                        <p>{props.note}</p>
                    </StyledNoteRow>
                </Show>
            </StyledCardTopContent>
            <Show
                when={props.isLoading}
                fallback={
                    !props.events.length && !props.error ? (
                        <StyledCardTopContent>
                            <em>Inga mål hittades</em>
                        </StyledCardTopContent>
                    ) : null
                }
            >
                <SkeletonLoader height={80} rounded={false} numBlocks={2} />
            </Show>
            <Show when={props.error}>
                <StyledErrorContent>{props.error}</StyledErrorContent>
            </Show>
            <For each={props.events}>
                {(event: DaytimeDegreesItemEventType) => (
                    <DaytimeDegreesItemEvent
                        crop={props.crop}
                        categories={props.categories}
                        allTreatments={props.treatments}
                        hideEvent={() => props.hideEvent(event.treatments)}
                        {...event}
                    />
                )}
            </For>
            <StyledRemoveItemLink onClick={props.removeItem} role="button">
                Ta bort
            </StyledRemoveItemLink>
        </StyledDaytimeDegreesCard>
    );
};
