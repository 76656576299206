import { onMount } from 'solid-js';
import type {SearchFieldProps} from './search-field-types.d';
import {StyledSearchInput} from './search-field.styles';

/**
 * Takes a list of options and props and upon change of the search input
 * reports the list of filtered options.
 */
export const SearchField = (props: SearchFieldProps) => {
    let input: any;

    onMount(() => {
        input.focus();
    });

    const filterOptions = (val: string) => {
        props.onSearchFieldChange(val);
        const visibleOptions = props.options.filter(option => {
            const optionText = option.text.toLowerCase();
            return optionText.includes(props.value.toLowerCase());
        });
        props.reportVisibleOptions(visibleOptions);
    }

    return (
        <StyledSearchInput
            value={props.value}
            onInput={(e: any) => filterOptions(e.target.value)}
            placeholder={props.placeholder}
            ref={input}
        />
    );
}