import { Image } from '../image/image';
import type { AdProps } from './ad.d';
import { StyledAd } from './ad.styles';

export const Ad = (props: AdProps) => (
    <StyledAd>
        <a href={props.ad.url} target="_blank">
            <Image src={props.ad.imageSrc} crop="original" />
        </a>
    </StyledAd>
);
