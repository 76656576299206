import { Link } from '@solidjs/router';
import { For, mergeProps } from 'solid-js';
import { StyledBreadCrumbs } from './breadcrumbs.styles';
import { StyledBreadCrumbDivider, StyledPrompt } from './breadcrumbs.styles';

export const BreadCrumbs = (props: BreadCrumbsProps) => {
    const mergedProps = mergeProps(
        {
            links: [],
            showHomeLink: true,
            prompt: '',
        },
        props
    );

    const links = (): BreadCrumbLink[] => {
        return mergedProps.showHomeLink
            ? [
                  {
                      text: 'Start',
                      url: '/',
                  },
                  ...mergedProps.links,
              ]
            : mergedProps.links;
    };

    return (
        <StyledBreadCrumbs>
            <For each={links()}>
                {(link: BreadCrumbLink) => (
                    <>
                        <Link href={link.url}>{link.text}</Link>
                        <StyledBreadCrumbDivider>/</StyledBreadCrumbDivider>
                    </>
                )}
            </For>
            <StyledPrompt>{mergedProps.prompt}</StyledPrompt>
        </StyledBreadCrumbs>
    );
};

export type BreadCrumbsProps = {
    links?: BreadCrumbLink[];
    showHomeLink?: boolean;
    prompt?: string;
};

export type BreadCrumbLink = {
    text: string;
    url: string;
};
