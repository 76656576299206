import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';

export const StyledSearchContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.red};
    padding: 2rem 1rem;
    color: #262626;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
        border: 1px solid white;
    }
`;

export const StyledSearchContainerInner = styled.div`
    max-width: 880px;
    margin: 0 auto;
    width: 100%;
`;

export const StyledLoadingMessage = styled.div`
    margin: 1rem;
    color: white;
`;
