import { gql } from 'graphql-request';

export const regNoSearchQuery = gql`
    query regNoSearch($searchTerm: String!) {
        regNoSearch(searchTerm: $searchTerm) {
            name
            id
            regNumber
            approvalCeasedDate
            usageForbiddenDate
            matches {
                indices
                value
                key
            }
        }
    }
`;
