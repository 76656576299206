import { ParentProps } from 'solid-js';
import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';

const StyledShiny = styled.div`
    position: absolute;
    top: -5px;
    right: -72px;
    color: ${colors.white};
    background-color: rgba(150, 45, 50, 0.75);
    transform: rotate(45deg);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 0.75rem 80px;

    @media (min-width: 769px) {
        right: -50px;
        top: 10px;
        background-color: rgba(150, 45, 50, 0.9);
    }
`;

export const Shiny = (props: ParentProps) => {
    return <StyledShiny>{props.children}</StyledShiny>;
};
