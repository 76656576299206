import { styled } from 'solid-styled-components';

export const StyledUpmaContentContainer = styled.div`
    container-type: inline-size;
`;

export const StyledUpmaContent = styled.div`
    display: flex;
    flex-direction: column;

    @container (min-width: 769px) {
        gap: 2rem;
        flex-direction: row;
    }
`;

export const StyledUPMAInstructionsForUse = styled.div`
    width: 100%;

    @container (min-width: 769px) {
        width: 60%;
    }
`;

export const StyledUPMAInformation = styled.div`
    width: 100%;
    margin-top: 2rem;

    @container (min-width: 769px) {
        margin-top: 0;
        width: 38%;
    }
`;

export const StyledUpmaProductTitle = styled.div`
    font-size: 18px;
    padding-bottom: 1rem;
`;

export const StyledUpmaInformationContainer = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;

    &.is-visible {
        margin-top: 1rem;
        height: auto;
        max-height: 800px;
    }
`;

export const StyledMoreInformationTrigger = styled.div`
    display: flex;
    gap: 1rem;
    font-weight: bold;
    font-size: 1rem;

    span {
        cursor: pointer;
    }
`;
