import { createMemo, createSignal, For, Show } from 'solid-js';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { Select } from '../select/select';
import { ContactArea, ContactsProps } from './contacts.d';
import {
    StyledContacts,
    StyledContactHeadline,
    StyledContactsList,
    StyledSelectWrapper,
    StyledNoContactsContainer,
    StyledAddressContainer,
} from './contacts.styles';
import { SelectOption } from '../select/select.types';
import { Contact as ContactComponent } from './contact';
import { AdsContainer } from '../../containers/ads/ads-container';
import { StyledVerticalSpace } from '../layout/layout.styles';

export const Contacts = (props: ContactsProps) => {
    const [selectedContactArea, setSelectedContactArea] = createSignal<string>('');

    const contactAreaOptions = (): SelectOption[] => {
        const options: SelectOption[] = [
            {
                value: '',
                text: ' -- Välj plats --',
            },
        ];

        props.contactAreas.forEach(({ slug, name, children }) => {
            options.push({ value: slug, text: `<strong>${name}</strong>`, disabled: true });

            children?.forEach((child: ContactArea) => {
                options.push({ value: child.slug, text: child.name });
            });
        });

        return options;
    };

    const currentContactAreaAddress = createMemo(() => {
        for (const contactArea of props.contactAreas) {
            if (selectedContactArea() === contactArea.slug) {
                return contactArea?.address || '';
            }

            if (!contactArea.children?.length) {
                continue;
            }

            for (const child of contactArea.children) {
                if (selectedContactArea() === child.slug) {
                    return child?.address || '';
                }
            }
        }

        return '';
    });

    const contactsInRegion = createMemo(() => {
        if (selectedContactArea() === '') {
            return [];
        }

        return props.contacts
            .filter(({ contactAreas }) => !!contactAreas.find(({ slug }) => slug === selectedContactArea()))
            .map((contact) => {
                const contactAreaForContact = contact.contactAreas.find(({ slug }) => slug === selectedContactArea());

                return {
                    ...contact,
                    priority: contactAreaForContact?.priority || 1,
                };
            })
            .sort((a, b) => a.priority - b.priority || a.name.localeCompare(b.name, 'sv'));
    });

    return (
        <StyledContacts>
            <StyledContactHeadline>Kontakta oss</StyledContactHeadline>
            <StyledSelectWrapper>
                <Select mode="basic" onChange={setSelectedContactArea} options={contactAreaOptions()} value={selectedContactArea()} />
            </StyledSelectWrapper>
            <Show when={selectedContactArea() !== ''}>
                <Show when={currentContactAreaAddress()} fallback={<StyledVerticalSpace size={2.5} />}>
                    <StyledAddressContainer innerHTML={currentContactAreaAddress()} />
                </Show>
                <StyledContactsList>
                    <For
                        each={contactsInRegion()}
                        fallback={<StyledNoContactsContainer>Inga kontakter hittades i området</StyledNoContactsContainer>}
                    >
                        {(contact) => <ContactComponent contact={contact} />}
                    </For>
                </StyledContactsList>
            </Show>

            <AdsContainer placement={Placements.Contacts} />
        </StyledContacts>
    );
};
