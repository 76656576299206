import { StyledLink, StyledLinkTag } from './link.style';
import type { LinkProps } from './link.d';
import { colors } from '../../style/style-variables';

export const Link = (props: LinkProps) => (
    <StyledLink>
        <StyledLinkTag href={props.href} onClick={props?.onClick}>
            {props.children}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill={colors.red} d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
        </StyledLinkTag>
    </StyledLink>
);
