import { gql } from 'graphql-request';

export const getTreatmentQuery = gql`
    query treatment($slug: String) {
        treatment: getTreatment(slug: $slug) {
            slug
            name
            description
            purpose {
                slug
                name
            }
        }
    }
`;

export const getTreatmentsQuery = gql`
    query treatments($searchTerm: String, $limit: Int, $offset: Int) {
        treatments: getTreatments(searchTerm: $searchTerm, limit: $limit, offset: $offset) {
            count
            rows {
                slug
                name
                description
                purpose {
                    slug
                    name
                }
            }
        }
    }
`;
