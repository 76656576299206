import { NavLink } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';

export const StyledImageLinkCard = styled(NavLink)`
    background: ${colors.white};
    border-radius: 8px;
    height: 132px;
    display: flex;
    flex-direction: column;
    padding: 2px;
    box-shadow: 0px 8px 8px -2px rgba(0, 0, 0, 0.1);

    @media (min-width: 480px) and (max-width: 768px) {
        width: 220px;
    }

    @media (min-width: 1080px) {
        height: 215px;

        &:hover {
            background: ${colors.yellow};
        }
    }
`;

export const StyledCardImage = styled.div<StyledCardImageProps>`
    height: 70%;
    background: url(${(p) => p.imageUrl});
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;
export type StyledCardImageProps = {
    imageUrl?: string;
};

export const StyledCardBottom = styled.div<StyledCardBottomProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: ${(p) => colors[p.backgroundColor]};
    border-radius: 0 0 8px 8px;
`;
export type StyledCardBottomProps = {
    backgroundColor: 'red' | 'green' | 'blue';
};

export const StyledCardText = styled.h2`
    font-size: 16px;
    color: ${colors.white};
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;

    @media (min-width: 1080px) {
        font-size: 24px;
    }
`;
