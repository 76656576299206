import { styled } from 'solid-styled-components';

export const StyledSkeletonLoader = styled.div<{ height: number; rounded: boolean; marginBottom: string }>`
    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }

    display: block;
    border-radius: 8px;
    margin-bottom: ${(p) => p.marginBottom};
    height: ${(p) => p.height}px;
    width: 100%;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: ${(p) => (p.rounded ? '5px' : '0')};
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
`;
