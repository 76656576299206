import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import { colors } from '../../style/style-variables';
import { StyleProps } from './link-button-types';

export const StyledLinkButton = styled(Link)<StyleProps>`
    background: ${(p) => (p.variant === 'primary' ? colors.red : 'none')};
    color: ${(p) => (p.variant === 'primary' ? '#fff' : '#607f0b')};
    font-weight: 700;
    border-radius: 8px;
    padding: ${(p) => (p.size === 'small' ? '0.25rem 0.75rem' : '0.75rem 1.5rem')};
    border: ${(p) => (p.variant === 'primary' ? 0 : '1px solid #607f0b')};
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};

    &:visited {
        color: ${(p) => (p.variant === 'primary' ? '#fff' : '#607f0b')};
    }
`;
