import { styled } from 'solid-styled-components';

export const StyledConditionsOfUseTableContainer = styled.div`
    border-radius: 8px;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 1rem;
    width: 100%;
    display: flex;
`;

export const StyledConditionsOfUseTableHeader = styled.div`
    @media screen and (min-width: 860px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
`;

export const StyledTable = styled.table<StyledTableProps>`
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    line-height: 1.5em;

    ${(p) => (p.fullWidth ? 'width: 100%;' : '')}

    @media screen and (max-width: 768px) {
        font-size: 13px;
    }
`;
type StyledTableProps = {
    fullWidth: boolean;
};

export const StyledLeftmostTableHeader = styled.th`
    position: sticky;
    left: 0;
    text-align: left;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    padding: 12px 16px 12px 12px;
    border: 1px solid #e4e4e4;
    border-left: none;
    border-top: none;
    background-color: #fffaeb;
    font-weight: 400;
    vertical-align: top;

    @media screen and (max-width: 768px) {
        width: 140px;
        max-width: 140px;
        min-width: 140px;
    }
`;

export const StyledLeftmostTableCell = styled.td`
    position: sticky;
    left: 0;
    text-align: left;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    padding: 12px 16px 12px 12px;
    border: 1px solid #e4e4e4;
    border-left: none;
    border-top: none;
    background-color: #fffaeb;
    vertical-align: top;
    -webkit-hyphens: auto;
    hyphens: auto;

    @media screen and (max-width: 768px) {
        width: 140px;
        max-width: 140px;
        min-width: 140px;
    }
`;

export const StyledTableRow = styled.tr`
    background: white;
    &:nth-of-type(odd) {
        background: #f9f9f9;
    }
`;

export const StyledTableHeader = styled.th<StyledTableHeaderProps>`
    text-align: left;
    padding: 12px 16px 12px 12px;
    border: 1px solid #e4e4e4;
    border-left: none;
    border-top: none;
    max-width: 600px;
    background-color: white;
    ${(p) => (p.selectedSituationsAmount > 2 ? 'width: 320px;' : '')}

    @media screen and (max-width: 768px) {
        ${(p) => (p.selectedSituationsAmount > 1 ? 'width: 160px;' : '')}
        padding: 12px 8px;
    }
`;
type StyledTableHeaderProps = {
    selectedSituationsAmount: number;
};

export const StyledTableCell = styled.td<StyledTableCellProps>`
    text-align: left;
    padding: 12px 16px 12px 12px;
    border: 1px solid #e4e4e4;
    border-left: none;
    border-top: none;
    max-width: 600px;
    ${(p) => (p.selectedSituationsAmount > 2 ? 'width: 320px;' : '')}
    white-space: pre-wrap;
    vertical-align: top;

    @media screen and (max-width: 768px) {
        ${(p) => (p.selectedSituationsAmount > 1 ? 'width: 160px;' : '')}
        padding: 12px 8px;
    }
`;
type StyledTableCellProps = {
    selectedSituationsAmount: number;
};

export const StyledTableCellContent = styled.div<{ expanded: boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.expanded ? 'none' : '2')};
    -webkit-box-orient: vertical;
`;

export const StyledPlaceHolder = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
        font-size: 17px;
        font-weight: 700;
    }

    p {
        font-size: 13px;
        font-style: italic;
        max-width: 248px;
    }
`;

export const StyledConditionsOfUse = styled.div<StyledConditionsOfUseProps>`
    position: relative;
    border-radius: 6px;
    ${(p) => (p.selectedSituationsAmount < 3 ? 'box-shadow: 0px 1px 1px 0px #00000033;' : '')}

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 8px;
        ${(p) => (p.selectedSituationsAmount > 2 ? 'box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;' : '')}
    }

    @media screen and (max-width: 768px) {
        ${(p) => (p.selectedSituationsAmount < 2 ? 'box-shadow: 0px 1px 1px 0px #00000033;' : '')}

        &::before {
            ${(p) => (p.selectedSituationsAmount > 1 ? 'box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;' : '')}
        }
    }
`;
type StyledConditionsOfUseProps = {
    selectedSituationsAmount: number;
};
