import { styled } from 'solid-styled-components';
import {colors} from '../../../style/style-variables';

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 70px;
    padding: 0 1rem;
    background: ${colors.red};
    color: white;
    border-radius: 12px 12px 0 0;

    button {
        padding: 0;
        width: 40px;
        height: 40px;
    }

    svg {
        height: 30px;
        width: 30px;
    }

    h1 {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        border-radius: 0 0 0 0;
    }
`;

export const StyledHomeButtonContainer = styled.div`
    @media (min-width: 769px) {
        opacity: 0;
    }
`;

export const StyledHeaderText = styled.h1``;
