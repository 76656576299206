import { useNavigate } from '@solidjs/router';
import { StyledHeader, StyledHomeButtonContainer, StyledHeaderText } from './header.styles';
import type { HeaderProps } from './header.d';
import IconButton from '../../icon-button/icon-button';

export const Header = (props: HeaderProps) => {
    const navigate = useNavigate();

    return (
        <StyledHeader>
            <IconButton
                onClick={() => {
                    localStorage.setItem('direction', 'back');
                    if (props.backLink) {
                        navigate(props.backLink);
                    } else {
                        navigate(-1);
                    }
                }}
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                    </svg>
                }
                color="yellow"
            />
            <StyledHeaderText>{props?.header || ''}</StyledHeaderText>
            <StyledHomeButtonContainer>
                <IconButton
                    onClick={() => {
                        localStorage.setItem('direction', 'back');
                        navigate('/');
                    }}
                    icon={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-6h2v6z" />
                        </svg>
                    }
                    color="yellow"
                    variant="secondary"
                />
            </StyledHomeButtonContainer>
        </StyledHeader>
    );
};
