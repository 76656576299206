import { Accessor, For } from 'solid-js';
import { Box } from '../layout/box/box';
import { StyledVerticalSpace } from '../layout/layout.styles';
import { Pagination } from '../pagination/pagination';
import { PaginationProps } from '../pagination/pagination-types';
import type { Upma as UpmaType } from '../products/product-types';
import { Upma } from '../products/upma/upma';

type Props = {
    upmas: Accessor<{
        upmaList?: {
            rows?: UpmaType[];
        };
    }>;
    pagination: PaginationProps;
};

export const UpmaList = (props: Props) => (
    <>
        <For each={props.upmas()?.upmaList?.rows} fallback={<Box>Inga beslut hittades</Box>}>
            {(upma) => <Upma upma={upma} />}
        </For>
        <StyledVerticalSpace size={1} />
        <Pagination {...props.pagination} />
    </>
);
