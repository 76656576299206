import { styled } from 'solid-styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const IFrameWrapper = styled.div`
    width: 100%;
    height: 100%;
`;
