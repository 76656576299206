import { useContext } from 'solid-js';
import { Title } from '@solidjs/meta';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Screen } from '../../components/layout/screen/screen';
import { ProductCategories } from '../../components/product-categories/product-categories';
import { getProductCategoriesQuery } from '../../graphql/queries/product-categories';

export const ProductCategoriesContainer = () => {
    const { createCachedResource } = useContext(AppContext);
    const [productCategoriesData] = createCachedResource(getProductCategoriesQuery, { limit: 50 });

    return (
        <Screen>
            <Title>Produkter - Gullvikspraktikan</Title>
            <ProductCategories productCategoriesData={productCategoriesData} />
        </Screen>
    );
};
