import { createEffect, createSignal, Show } from 'solid-js';
import { useLocation } from '@solidjs/router';
import Button from '../button/button';
import { StyledDocumentSignedBox } from './document-signed-styled';
import { Transition } from 'solid-transition-group';

export const DocumentSignedNotice = () => {
    const [showNotice, setShowNotice] = createSignal(false);
    const { search } = useLocation();

    createEffect(() => {
        const params = new URLSearchParams(search);
        for (const [key, value] of params) {
            if (key === 'signerad' && value === '1') {
                setShowNotice(true);
            }
        }
    });

    return (
        <Transition name="notice-fade">
            <Show when={showNotice()}>
                <StyledDocumentSignedBox>
                    <p>Tack, din kundförsäkran är nu signerad.</p>
                    <Button onClick={() => setShowNotice(false)} label="Stäng" />
                </StyledDocumentSignedBox>
            </Show>
        </Transition>
    );
};
