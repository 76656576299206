import { JSX, ParentProps, Show, createSignal, mergeProps } from 'solid-js';
import { StyledExpandableBox, StyledShowChildrenButton, StyledExpandableBoxMain } from './expandable-box.styled';

interface ExpandableBoxProps extends ParentProps {
    expandableChildren: JSX.Element;
    showChildrenButtonText: string;
    hideChildrenButtonText: string;
}
const defaultProps = {
    showChildrenButtonText: 'Visa mer',
    hideChildrenButtonText: 'Dölj',
};

export const ExpandableBox = (componentProps: ExpandableBoxProps) => {
    const props = mergeProps(defaultProps, componentProps);
    const [childrenVisible, setChildrenVisible] = createSignal(false);

    return (
        <StyledExpandableBox>
            <StyledExpandableBoxMain>
                {props.children}
                <Show when={childrenVisible()}>{props.expandableChildren}</Show>
            </StyledExpandableBoxMain>
            <StyledShowChildrenButton onClick={() => setChildrenVisible(!childrenVisible())}>
                <div>{childrenVisible() ? '-' : '+'}</div>
                <div>{childrenVisible() ? props.hideChildrenButtonText : props.showChildrenButtonText}</div>
            </StyledShowChildrenButton>
        </StyledExpandableBox>
    );
};
