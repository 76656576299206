import { styled } from 'solid-styled-components';

const StyledDisclaimer = styled.div`
    padding-top: 2rem;
    color: #eee;
    font-style: italic;
    text-align: center;

    @media (max-width: 480px) {
        padding: 1rem;
    }
`;

export const Disclaimer = () => (
    <StyledDisclaimer>
        Gullviks reserverar sig för felskrivningar och påminner om att det är slutanvändaren som använder/innehar en kemisk produkt, som ansvarar för
        att produkten används och lagras enligt etikett.
    </StyledDisclaimer>
);
