import { gql } from 'graphql-request';

export const getFlashesQuery = gql`
    query flashes($limit: Int, $offset: Int, $placement: String, $activeOnly: Boolean) {
        flashes: getFlashes(limit: $limit, offset: $offset, placement: $placement, activeOnly: $activeOnly) {
            rows {
                text
            }
        }
    }
`;
